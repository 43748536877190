import {
  GalleryIndex,
  GalleryItems,
  menuItems,
  menuItemsIndex,
  workshopMenuItem,
  WallOfFameItems,
} from "../../const/Menu/MenuConst";
import { VideoString } from "../../const/assets/VideoString";
import {
  TransitionType,
  HotspotType,
  HotspotButtonType,
} from "../../const/fixed/Types";
import { ImageString } from "../../const/assets/ImageString";
import { isIOS, isMobileOnly, isTablet } from "react-device-detect";

export let InfoHotspots = {
  Agenda: {
    id: "Agenda",
    enabled: true,
    name: "Agenda",
    desk_style: {
      margin: "9.4% 22.44%",
    },
    mobile_style: {
      margin: "9% 22.6%",
    },
    get style() {
      return isMobileOnly ? this.mobile_style : this.desk_style;
    },
    hotspotType: HotspotType.pdf,
    buttonType: HotspotButtonType.default,
    link: "/assets/content/Agenda.pdf",
  },
  "pdf-2": {
    id: "Contact-us",
    enabled: true,
    name: "Contact Us",
    desk_style: {
      margin: "-4% 5%",
    },
    mobile_style: {
      margin: "2% 5.6%",
    },
    get style() {
      return isMobileOnly ? this.mobile_style : this.desk_style;
    },
    hotspotType: HotspotType.pdf,
    buttonType: HotspotButtonType.default,
    link: "/assets/content/Contact.pdf",
  },
  // ITS_Support: {
  //   id: "ITS_Support",
  //   enabled: true,
  //   name: "ITS Support",
  //   desk_style: {
  //     margin: "4% 2%",
  //   },
  //   mobile_style: {
  //     margin: "6% 4.4%",
  //   },
  //   get style() {
  //     return isMobileOnly ? this.mobile_style : this.desk_style;
  //   },
  //   hotspotType: HotspotType.chatbot,
  //   buttonType: HotspotButtonType.named,
  // },
};

export let DiviSionBoothHotspost = {
  Consumer: {
    id: "Consumer",
    name: "Consumer Products",
    enabled: false,
    style: {
      margin: isMobileOnly ? "-6.5% -1%" : "-3.5% -2%",
    },
    hotspotType: HotspotType.transition,
    transitionType: TransitionType.changeComponent,
    newItem: GalleryItems[GalleryIndex.Consumer],
    buttonType: HotspotButtonType.default,
  },
  Luxe: {
    id: "Luxe",
    name: "L’Oréal Luxe",
    enabled: false,
    style: {
      margin: isMobileOnly ? "-2% -1%" : "1.5% -2%",
    },
    hotspotType: HotspotType.transition,
    transitionType: TransitionType.changeComponent,
    newItem: GalleryItems[GalleryIndex.Luxe],
    buttonType: HotspotButtonType.default,
  },

  Professional: {
    id: "Business",
    name: "Professional Products",
    enabled: false,
    style: {
      margin: isMobileOnly ? "-3% -21%" : "-2.9% -21%",
    },
    hotspotType: HotspotType.transition,
    transitionType: TransitionType.changeComponent,
    newItem: GalleryItems[GalleryIndex.Professional],
    buttonType: HotspotButtonType.default,
  },

  staticSite: {
    id: "staticSite",
    enabled: true,
    style: {
      margin: isMobileOnly ? "-3% -21%" : "-2.9% -21%",
    },
    name: "staticSite",
    hotspotType: HotspotType.iframe,
    buttonType: HotspotButtonType.default,
    link: "/imageMapper/index.html",
  },
  Active: {
    id: "Active",
    name: "Active Cosmetics",
    enabled: true,
    style: {
      margin: isMobileOnly ? "-2.5% 8%" : "-2.5% 8%",
    },
    hotspotType: HotspotType.transition,
    transitionType: TransitionType.changeComponent,
    newItem: GalleryItems[GalleryIndex.Active],
    buttonType: HotspotButtonType.default,
  },
  Throuwback_TUP: {
    id: "Throuwback_TUP",
    enabled: true,
    name: "About TUP",
    desk_style: {
      margin: "-13% 35%",
    },
    mobile_style: {
      margin: "-12.8% 35%",
    },
    get style() {
      return isMobileOnly ? this.mobile_style : this.desk_style;
    },
    hotspotType: HotspotType.video,
    buttonType: HotspotButtonType.default,
    link: "https://vimeo.com/770656815/a00659bf25",
  },

  Testimonials: {
    id: "Testimonials",
    enabled: true,
    name: "Testimonials",
    desk_style: {
      margin: "-9.6% 26%",
    },
    mobile_style: {
      margin: "-9% 26.5%",
    },
    get style() {
      return isMobileOnly ? this.mobile_style : this.desk_style;
    },
    hotspotType: HotspotType.video,
    buttonType: HotspotButtonType.default,
    link: "https://vimeo.com/774089549/f88ac4788b",
  },

  RelianceGreatIndia: {
    id: "RelianceGreatIndia",
    enabled: true,
    name: "GPTW",
    desk_style: {
      margin: "-7.4% 35.6%",
    },
    mobile_style: {
      margin: "-7% 35%",
    },
    get style() {
      return isMobileOnly ? this.mobile_style : this.desk_style;
    },
    hotspotType: HotspotType.video,
    buttonType: HotspotButtonType.default,
    link: "https://vimeo.com/770660301/bd5d3f1dc1",
  },

  Accolades: {
    id: "Accolades",
    enabled: true,
    name: "Accolades",
    desk_style: {
      margin: "-4.2% 26%",
    },
    mobile_style: {
      margin: "-3.5% 26.5%",
    },
    get style() {
      return isMobileOnly ? this.mobile_style : this.desk_style;
    },
    // hotspotType: HotspotType.video,
    hotspotType: HotspotType.image,
    buttonType: HotspotButtonType.default,
    // link: "https://www.youtube.com/watch?v=Ppwmmf_wacA&t=80s",
    link: "/assets/images/agendaImg.jpeg",
  },
};

export let ModiFaceHotspost = {
  "pdf-1": {
    id: "modi_loreal",
    enabled: true,
    name: "L’Oréal Paris",
    desk_style: {
      margin: "4.5% -3.5%",
    },
    mobile_style: {
      margin: "7% -6.5%",
    },
    get style() {
      return isMobileOnly ? this.mobile_style : this.desk_style;
    },
    hotspotType: HotspotType.modiFace,
    buttonType: HotspotButtonType.named,
    link: "https://www.lorealparis.co.in/virtual-try-on",
    qrLink: "/assets/images/loreal-qr.png",
  },
  "pdf-2": {
    id: "modi_garnier",
    enabled: true,
    name: "Garnier",
    desk_style: {
      margin: "4.5% 4.95%",
    },
    mobile_style: {
      margin: "7% 3.6%",
    },
    get style() {
      return isMobileOnly ? this.mobile_style : this.desk_style;
    },
    hotspotType: HotspotType.modiFace,
    buttonType: HotspotButtonType.named,
    link: "https://www.garnier.in/hair-color-virtual-try-on",
    qrLink: "/assets/images/garnier-qr.png",
  },
  "pdf-3": {
    id: "modi_maybelline",
    enabled: true,
    name: "Maybelline",
    desk_style: {
      margin: "4.5% 12%",
    },
    mobile_style: {
      margin: "7% 10.6%",
    },
    get style() {
      return isMobileOnly ? this.mobile_style : this.desk_style;
    },
    hotspotType: HotspotType.modiFace,
    buttonType: HotspotButtonType.named,
    link: "https://www.maybelline.co.in/virtual-try-on",
    qrLink: "/assets/images/mayb-qr.png",
  },
};
export let busHotspots = {
  "pdf-1": {
    id: "How_to_be_Ergonomic",
    enabled: true,
    // name: "How to be Ergonomic",
    desk_style: {
      margin: "1% -1%",
    },
    mobile_style: {
      margin: "7% -9.4%",
    },
    get style() {
      return isMobileOnly ? this.mobile_style : this.desk_style;
    },
    hotspotType: HotspotType.anchor,
    // buttonType: HotspotButtonType.named,
    link: "/assets/content/How to be Ergonomic.pdf",
  },
  "pdf-2": {
    id: "Best_practices",
    enabled: true,
    // name: "2021 Staff Conference Best Practices",
    desk_style: {
      margin: "4% -2%",
    },
    mobile_style: {
      margin: "-9% -15.4%",
    },
    get style() {
      return isMobileOnly ? this.mobile_style : this.desk_style;
    },
    hotspotType: HotspotType.pdf,
    // buttonType: HotspotButtonType.named,
    link: "/assets/content/Best practices.pdf",
  },
  "pdf-3": {
    id: "Program",
    enabled: true,
    // name: "Program",
    desk_style: {
      margin: "-6% 13.5%",
    },
    mobile_style: {
      margin: "-9% -36.4%",
    },
    get style() {
      return isMobileOnly ? this.mobile_style : this.desk_style;
    },
    hotspotType: HotspotType.pdf,
    // buttonType: HotspotButtonType.named,
    link: "/assets/content/2. Program.pdf",
  },
};

export let MeetTheHeroHotspot = {
  MeetTheHeroVideo: {
    id: "MeetTheHeroVideo",
    enabled: true,
    // name: 'Video',

    desk_style: {
      margin: "0% -6%",
    },
    mobile_style: {
      margin: "0% -6%",
    },
    get style() {
      return isMobileOnly ? this.mobile_style : this.desk_style;
    },
    hotspotType: HotspotType.video,
    link: "https://vimeo.com/497278291",
  },
};
export let AudiData = {
  introVideo: VideoString.AUDITORIUM,
  link: "https://www.youtube.com/watch?v=z1rUP17fayA",
  // placementStyle: { margin: "12.23% 34.43%", width: "31.78%", height: "42.2%" },
  placementStyle: {
    margin: "3.9% 30.3%",
    width: "calc(55.4vh * 1.77)",
    height: "55.4vh",
  },
  workshopPlacement: { margin: "14.5% 38.2%", width: "23.9%", height: "31.4%" },
};
export let ExploreData = {
  introVideo: VideoString.BREAKOUTLOOP,
  link: "https://www.youtube.com/watch?v=z1rUP17fayA",
};

export let TeamsData = {
  "pdf-1": {
    id: "pdf=1",
    name: "ABOUT THE ACTIVITY",
    description: "",
    link: "/assets/content/TeamBuildingInfo.pdf",
    button: "READ MORE",
  },
  link1: {
    id: "link1",
    name: "Team Leadership",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    link: "https://teams.microsoft.com/l/meetup-join/19%3ameeting_ODFmOTI3MTUtZTFmNy00NTgwLWExMzItOTI2MzkyNmFmYTlk%40thread.v2/0?context=%7b%22Tid%22%3a%2225c1df4b-00ea-4e39-98bd-5f1143c5c5df%22%2c%22Oid%22%3a%22abcb8352-f7bf-475a-82d7-cb6c48c7d70f%22%7d",
  },
  link2: {
    id: "link2",
    name: "Team Mindset",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    link: "https://www.r1rcm.com/",
  },
  link3: {
    id: "link3",
    name: "Team Values",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    link: "https://www.r1rcm.com/",
  },
  link4: {
    id: "link4",
    name: "Team Rising",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    link: "https://www.r1rcm.com/",
  },
  link5: {
    id: "link5",
    name: "Team Leadership",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    link: "https://www.r1rcm.com/",
  },
  link6: {
    id: "link6",
    name: "Team Mindset",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    link: "https://www.r1rcm.com/",
  },
  link7: {
    id: "link7",
    name: "Team Values",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    link: "https://www.r1rcm.com/",
  },
  link8: {
    id: "link8",
    name: "Team Rising",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    link: "https://www.r1rcm.com/",
  },
  link9: {
    id: "link9",
    name: "Team Values",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    link: "https://www.r1rcm.com/",
  },
  link10: {
    id: "link10",
    name: "Team Rising",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    link: "https://www.r1rcm.com/",
  },
};
export const BreakoutRoomHotspot = {
  "workshop-1": {
    id: "humanResources",
    forAdmin: "workshop-1",
    enabled: true,
    frame: VideoString.Room1Frame,
    initalImage: ImageString.Room1Image,
    get workshop() {
      return {
        status: window.isWkAdmin ? true : window.workshop === "workshop-1",
      };
    },
    name: "workshop-1",
    title:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi animi atque quidem culpa aliquid esse, temporibusest minima quod nisi.",
    style: {
      margin: "6% -19.5%",
    },
    hotspotType: HotspotType.transition,
    transitionVideo: null,
    buttonType: HotspotButtonType.default,
    transitionType: TransitionType.changeComponent,
    newItem: workshopMenuItem,
    link: "https://www.youtube.com/embed/rxdtAJomd_0",
  },
  "workshop-2": {
    id: "generalManagement",
    forAdmin: "workshop-2",
    enabled: true,
    frame: VideoString.Room2Frame,
    initalImage: ImageString.Room2Image,

    get workshop() {
      return {
        status: window.isWkAdmin ? true : window.workshop === "workshop-2",
      };
    },
    name: "workshop-2",
    title:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi animi atque quidem culpa aliquid esse, temporibusest minima quod nisi.",
    style: {
      margin: "6% 14.5%",
    },
    hotspotType: HotspotType.transition,
    transitionVideo: null,
    buttonType: HotspotButtonType.default,
    transitionType: TransitionType.changeComponent,
    newItem: workshopMenuItem,
    link: "https://www.youtube.com/embed/rxdtAJomd_0",
  },
  "workshop-3": {
    id: "finance",
    forAdmin: "workshop-3",
    frame: VideoString.Room3Frame,
    initalImage: ImageString.Room3Image,

    get workshop() {
      return {
        status: window.isWkAdmin ? true : window.workshop === "workshop-3",
      };
    },
    enabled: true,
    name: "workshop-3",
    title:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi animi atque quidem culpa aliquid esse, temporibusest minima quod nisi.",
    style: {
      margin: "-2.5% -14%",
    },
    hotspotType: HotspotType.transition,
    transitionVideo: null,
    buttonType: HotspotButtonType.default,
    transitionType: TransitionType.changeComponent,
    newItem: workshopMenuItem,
    link: "https://www.youtube.com/embed/rxdtAJomd_0",
  },
  "workshop-4": {
    id: "operations",
    forAdmin: "workshop-4",
    frame: VideoString.Room4Frame,
    initalImage: ImageString.Room4Image,

    get workshop() {
      return {
        status: window.isWkAdmin ? true : window.workshop === "workshop-4",
      };
    },
    enabled: true,
    name: "workshop-4",
    title:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi animi atque quidem culpa aliquid esse, temporibusest minima quod nisi.",
    style: {
      margin: "-2.7% 8%",
    },
    hotspotType: HotspotType.transition,
    transitionVideo: null,
    buttonType: HotspotButtonType.default,
    transitionType: TransitionType.changeComponent,
    newItem: workshopMenuItem,
    link: "https://www.youtube.com/embed/rxdtAJomd_0",
  },
  "workshop-5": {
    id: "workshop-5",
    forAdmin: "workshop-5",
    frame: VideoString.Room5Frame,
    get workshop() {
      return {
        status: window.isWkAdmin ? true : window.workshop === "workshop-5",
      };
    },
    enabled: false,
    name: "workshop-5",
    title:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi animi atque quidem culpa aliquid esse, temporibusest minima quod nisi.",
    style: {
      margin: "-6% -8.5%",
    },
    hotspotType: HotspotType.transition,
    transitionVideo: null,
    buttonType: HotspotButtonType.default,
    transitionType: TransitionType.changeComponent,
    newItem: workshopMenuItem,
    link: "https://www.youtube.com/embed/rxdtAJomd_0",
  },
  "workshop-6": {
    id: "workshop-6",
    forAdmin: "workshop-6",
    frame: VideoString.Room6Frame,
    get workshop() {
      return {
        status: window.isWkAdmin ? true : window.workshop === "workshop-6",
      };
    },
    enabled: false,
    name: "workshop-6",
    title:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi animi atque quidem culpa aliquid esse, temporibusest minima quod nisi.",
    style: {
      margin: "-6% 7.5%",
    },
    hotspotType: HotspotType.transition,
    transitionVideo: null,
    buttonType: HotspotButtonType.default,
    transitionType: TransitionType.changeComponent,
    newItem: workshopMenuItem,
    link: "https://www.youtube.com/embed/rxdtAJomd_0",
  },
  "workshop-7": {
    id: "workshop-7",
    forAdmin: "workshop-7",
    frame: VideoString.Room7Frame,
    title:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi animi atque quidem culpa aliquid esse, temporibusest minima quod nisi.",
    get workshop() {
      return {
        status: window.isWkAdmin ? true : window.workshop === "workshop-7",
      };
    },
    enabled: false,
    name: "workshop-7",
    style: {
      margin: "-6% 24.5%",
    },
    hotspotType: HotspotType.transition,
    transitionVideo: null,
    buttonType: HotspotButtonType.default,
    transitionType: TransitionType.changeComponent,
    newItem: workshopMenuItem,
    link: "https://www.youtube.com/embed/rxdtAJomd_0",
  },
};
window.BreakoutRoomHotspot = BreakoutRoomHotspot;
export const ResourceCenterHotspot = {
  "video-1": {
    id: "Together, apart",
    enabled: true,
    name: "Together, apart",
    style: {
      margin: isMobileOnly ? "3% 17%" : "3% 18.5%",
    },
    hotspotType: HotspotType.video,
    buttonType: HotspotButtonType.default,
    link: "https://player.vimeo.com/video/593768174",
  },
  "video-2": {
    id: "FREEDOM GO TO BEYOND",
    enabled: true,
    name: "Loreal FREEDOM GO TO BEYOND",
    style: {
      margin: isMobileOnly ? "6% 23%" : "6% 26%",
    },
    hotspotType: HotspotType.video,
    buttonType: HotspotButtonType.default,
    link: "https://player.vimeo.com/video/593767169",
  },
  "video-3": {
    id: "Diversity and Inclusion",
    enabled: true,
    name: "Diversity and Inclusion",
    style: {
      margin: isMobileOnly ? "8% 17%" : "9% 18.5%",
    },
    hotspotType: HotspotType.video,
    buttonType: HotspotButtonType.default,
    link: "https://player.vimeo.com/video/593767060",
  },
  "video-4": {
    id: "Brandstorm Video",
    enabled: true,
    name: "Brandstorm Video 2",
    style: {
      margin: isMobileOnly ? "11% 23%" : "12% 26%",
    },
    hotspotType: HotspotType.pdf,
    buttonType: HotspotButtonType.default,
    link: "https://player.vimeo.com/video/593767023",
  },
  "video-5": {
    id: "WeAreLoreal",
    enabled: true,
    name: "#WeAreLoreal",
    style: {
      margin: isMobileOnly ? "13% 19%" : "14% 18.5%",
    },
    hotspotType: HotspotType.pdf,
    buttonType: HotspotButtonType.default,
    link: "https://player.vimeo.com/video/593766943",
  },
  "pdf-1": {
    id: "Men @ L’Oréal",
    enabled: true,
    name: "Men @ L’Oréal",
    style: {
      margin: isMobileOnly ? "-4% -8%" : "-5% -9%",
    },
    hotspotType: HotspotType.pdf,
    buttonType: HotspotButtonType.default,
    link: "/assets/content/Men @ L’Oréal.pdf",
  },
  "pdf-2": {
    id: "Forbes Coverage - LOC 2020",
    enabled: true,
    name: "Forbes Coverage - LOC 2020",
    style: {
      margin: isMobileOnly ? "-2% -15%" : "-2% -16%",
    },
    hotspotType: HotspotType.pdf,
    buttonType: HotspotButtonType.default,
    link: "/assets/content/Forbes Coverage - LOC 2020.pdf",
  },
  // videoPlayer: {
  //   id: "Videos",
  //   enabled: true,
  //   name: `Videos`,
  //   style: {
  //     margin: isMobileOnly ? "1% -28%" : "0% -14%",
  //   },
  //   hotspotType: HotspotType.videoPlayer,
  //   buttonType: HotspotButtonType.named,
  // },

  pdfPlayer: {
    id: "Your L'Oréal Journey",
    enabled: true,
    name: `PDFs`,
    style: {
      margin: isMobileOnly ? "-6% -15%" : "-6.5% -16%",
    },
    hotspotType: HotspotType.pdfPlayer,
    buttonType: HotspotButtonType.default,
  },
  pdfPlayer2: {
    id: "BeautyTech",
    enabled: true,
    name: `PDFs2`,
    style: {
      margin: isMobileOnly ? "-8% -8%" : "-9% -9%",
    },
    hotspotType: HotspotType.pdfPlayer,
    buttonType: HotspotButtonType.default,
  },
};
export const PhotoBoothHotspots = {
  Photo: {
    id: "Photobooth",
    enabled: true,
    style: {
      margin: "3.9% -2.5%",
    },
    hotspotType: HotspotType.iframe,
    // link: "/photo-axis/quality/index.html",
    link: "/photo-axis/reliancePhotobooth/index.html",
    name: "Photobooth",
  },
};

export const AgndaBothHotspots = {
  AgendaBooth: {
    id: "AgendaBooth",
    enabled: true,
    name: "Agenda",
    desk_style: {
      margin: "-17.6% 3%",
    },
    mobile_style: {
      margin: "-17.5% 2.6%",
    },
    get style() {
      return isMobileOnly ? this.mobile_style : this.desk_style;
    },
    hotspotType: HotspotType.pdf,
    buttonType: HotspotButtonType.default,
    link: "/web/viewer.html?file=%2Fassets%2Fcontent%2FAgenda.pdf",
  },
};

export const GameHotspots = {
  Football: {
    id: "Football",
    enabled: true,
    style: {
      margin: isMobileOnly ? "1.6% -13%" : "1% -12%",
      // backgroundColor: "#0032AE",
      color: "#fff",
    },
    name: "Football",
    hotspotType: HotspotType.iframe,
    buttonType: HotspotButtonType.default,
    link: "/football/game/index.html",
  },
  Quiz: {
    id: "Quiz",
    enabled: true,
    style: {
      margin: isMobileOnly ? "1.6% 6%" : "1% 6.5%",
      // backgroundColor: "#0032AE",
      color: "#fff",
    },
    name: "Quiz",
    hotspotType: HotspotType.iframe,
    buttonType: HotspotButtonType.deafult,
    link: "/quizGame/quizGame/index.html",
  },
};

export const TupPeopleWall = {
  TheUltimateWinner: {
    id: "TheUltimateWinner",
    enabled: true,
    name: "WallOfFame 1",
    style: {
      margin: isMobileOnly ? "-4.7% -10%" : "-4.3% -10.5%",
      // margin: isMobileOnly ? "-5.5% 4.5%" : "-5.5% 4.5%",
    },
    hotspotType: HotspotType.iframe,
    link: "/photo-axis/meettheteam/index.html?wall=meetTheTeamData",
  },

  TheUltimateRunnerUp: {
    id: "TheUltimateRunnerUp",
    enabled: true,
    name: "WallOfFame 2",
    style: {
      margin: isMobileOnly ? "-8.5% 4%" : "-9.5% 4.5%",

      // margin: isMobileOnly ? "-2.5% 16.5%" : "-2.5% 16.5%",
    },
    hotspotType: HotspotType.iframe,
    link: "/photo-axis/meettheteam/index.html?wall=meetTheTeamData2",
  },

  TheUltimateJuryChoice: {
    id: "TheUltimateJuryChoice",
    enabled: false,
    name: "WallOfFame 3",
    style: {
      margin: isMobileOnly ? "-5.5% 4.5%" : "-5.5% 4.5%",

      // margin: isMobileOnly ? "-5.5% -4%" : "-5.5% -4.2%",
    },
    hotspotType: HotspotType.iframe,
    link: "/photo-axis/meettheteam/index.html?wall=meetTheTeamData3",
  },

  TheUltimatePopularChoice: {
    id: "TheUltimatePopularChoice",
    enabled: true,
    name: "WallOfFame 4",
    style: {
      margin: isMobileOnly ? "-6.5% 16.5%" : "-7.5% 16%",

      // margin: isMobileOnly ? "-2.7% -16%" : "-2.7% -15.8%",
    },
    hotspotType: HotspotType.iframe,
    link: "/photo-axis/meettheteam/index.html?wall=meetTheTeamData4",
  },
};

export let LobbyBackHotspots = {
  LobbyFront: {
    id: "LobbyFront",
    enabled: true,
    style: {
      margin: isMobileOnly
        ? "-20.5% 18%"
        : isTablet
        ? "-20.5% 20%"
        : "-20.5% 28%",
      borderRadius: "0.1rem",
      border: "1.2px solid #fff",
      transform: "scale(1.1)",
      background: "#0032AE",
      minWidth: isMobileOnly && isIOS ? "10rem" : "10rem",
      color: "#fff",
      // animation: "none",
    },
    name: "LOBBY",
    buttonType: HotspotButtonType.named,
    hotspotType: HotspotType.transition,
    transitionVideo: VideoString.LOBBYRIGHTTOLEFT,
    transitionType: TransitionType.changeComponent,
    newItem: menuItems[menuItemsIndex.Lobby],
  },

  wallOfFamePeople: {
    id: "wallOfFamePeople",
    enabled: true,
    style: {
      margin: "4.5% 19.4%",
    },

    hotspotType: HotspotType.transition,
    transitionVideo: VideoString.LOBBYBACKTOWINNER,
    transitionType: TransitionType.withInComponent,
    newItem: ImageString.LOBBYBACKWINNERS,
    hotspot: TupPeopleWall,

    name: "Winners",
  },

  campusAwards: {
    id: "campusAwards",
    enabled: true,
    style: {
      margin: "2.5% -25.6%",
    },

    hotspotType: HotspotType.transition,
    transitionVideo: VideoString.LOBBYBACKTOAWARDS,
    transitionType: TransitionType.withInComponent,
    newItem: ImageString.LOBBYBACKAWARDS,

    name: "Awards",
  },
};

export let LobbyHotspots = {
  LobbyBack: {
    id: "LobbyBack",
    enabled: true,
    style: {
      margin: isMobileOnly
        ? "-20.5% 12%"
        : isTablet
        ? "-20.5% 17%"
        : "-20.5% 28%",
      padding: isMobileOnly ? "0.8rem 1rem" : "",
      fontSize: isMobileOnly ? "1rem" : "",
      minWidth: isMobileOnly && isIOS ? "10rem" : "10rem",
      borderRadius: "0.1rem",
      transform: "scale(1.1)",
      border: "1.2px solid #fff",
      background: "#0032AE",
      color: "#fff",
      // animation: "none",
    },
    name: "WALL OF FAME",

    buttonType: HotspotButtonType.wallRotate,
    hotspotType: HotspotType.transition,
    transitionVideo: VideoString.LOBBYLEFTTORIGHT,
    transitionType: TransitionType.changeComponent,
    newItem: WallOfFameItems[0],
  },
  Audi: {
    id: "Audi",
    enabled: true,
    style: {
      margin: "-3.5% 16%",
    },
    hotspotType: HotspotType.transition,
    transitionVideo: VideoString.LOBBYTOAudi,
    transitionType: TransitionType.changeComponent,
    newItem: menuItems[menuItemsIndex.Audi],
  },
  Explore: {
    id: "discoverRil",
    enabled: true,
    style: {
      margin: "-3.5% -23.2%",
    },
    hotspotType: HotspotType.transition,
    transitionVideo: VideoString.LOBBYTOBREAKOUT,
    transitionType: TransitionType.changeComponent,
    newItem: menuItems[menuItemsIndex.Explore],
  },
  BreakoutRoom: {
    id: "Career cafe",
    enabled: false,
    style: {
      margin: "-2.5% -2.2%",
    },
    hotspotType: HotspotType.transition,
    transitionVideo: VideoString.LOBBYTOCAREER,
    transitionType: TransitionType.changeComponent,
    newItem: menuItems[menuItemsIndex.BreakoutRoom],
    hotspot: BreakoutRoomHotspot,
  },

  // Infodesk: {
  //   id: "Infodesk",
  //   enabled: true,
  //   style: {
  //     margin: "11% -3%",
  //   },
  //   hotspotType: HotspotType.transition,
  //   transitionVideo: VideoString.LOOBYTOINFO,
  //   transitionType: TransitionType.withInComponent,
  //   newItem: ImageString.Infodesk,
  //   hotspot: InfoHotspots,
  //   name: "Infodesk",
  // },

  Agenda: {
    id: "Agenda",
    enabled: true,
    name: "Agenda",
    desk_style: {
      margin: "9.4% 22%",
    },
    mobile_style: {
      margin: "9% 22.6%",
    },
    get style() {
      return isMobileOnly ? this.mobile_style : this.desk_style;
    },
    hotspotType: HotspotType.transition,
    transitionVideo: VideoString.LOBBYTOAGENDA,
    transitionType: TransitionType.withInComponent,
    newItem: ImageString.AGENDA,
    hotspot: AgndaBothHotspots,
  },

  ProductBooth: {
    id: "ProductBooth",
    enabled: false,
    style: {
      margin: "6% -22%",
    },
    hotspotType: HotspotType.transition,
    transitionVideo: VideoString.LOBBYTODIVISION,
    transitionType: TransitionType.changeComponent,
    newItem: menuItems[menuItemsIndex.DivisionBooth],
    hotspot: DiviSionBoothHotspost,
    name: "Product Booth",
  },
  ModiFace: {
    id: "ModiFace",
    enabled: false,
    style: {
      margin: "6% 19%",
    },
    hotspotType: HotspotType.transition,
    transitionVideo: VideoString.LOOBYTOMODI,
    transitionType: TransitionType.withInComponent,
    newItem: ImageString.MODIFACE,
    hotspot: ModiFaceHotspost,
    name: "Mode Face",
  },
  "center-wall-video": {
    id: "cnter-wall-video",
    enabled: false,
    style: {
      margin: "-10% -2%",
    },
    hotspotType: HotspotType.video,
    link: "https://player.vimeo.com/video/593776961",
    name: "center-wall-video",
  },
  "right-wall-video": {
    id: "right-wall-video",
    enabled: false,
    style: {
      margin: "1% 40%",
    },
    hotspotType: HotspotType.video,
    link: "https://player.vimeo.com/video/593777462",
    name: "right-wall-video",
  },
  meetTheHero: {
    id: "meetTheHero",
    enabled: false,
    style: {
      margin: "-7.5% -28%",
    },
    hotspotType: HotspotType.transition,
    transitionType: TransitionType.changeComponent,
    newItem: menuItems[menuItemsIndex.Meet],
    hotspot: MeetTheHeroHotspot,
  },
  Photobooth: {
    id: "Photobooth",
    enabled: true,
    style: {
      margin: "2% 31%",
    },
    hotspotType: HotspotType.transition,
    transitionVideo: VideoString.LOBBYTOPHOTOBOOTH,
    transitionType: TransitionType.withInComponent,
    newItem: ImageString.PhotoBooth,
    hotspot: PhotoBoothHotspots,

    // hotspotType: HotspotType.iframe,
    // link: "/photo-axis/reliancePhotobooth/index.html",
    // name: "Photobooth",
  },

  Games: {
    id: "Games",
    enabled: true,
    style: {
      margin: "2.5% -36%",
    },
    hotspotType: HotspotType.transition,
    transitionVideo: VideoString.LOBBYTOGAME,
    transitionType: TransitionType.withInComponent,
    newItem: ImageString.Game,
    hotspot: GameHotspots,
    name: "Games",
  },
  Leader: {
    id: "Leader",
    enabled: false,
    style: {
      margin: "5% 10%",
    },
    hotspotType: HotspotType.transition,
    transitionVideo: VideoString.LOBBYTOCONNECT,
    transitionType: TransitionType.changeOverlayComponent,
    newItem: menuItems[menuItemsIndex.TeamBuilding],
  },
  VotingLink: {
    id: "VotingLink",
    enabled: true,
    name: "Voting Link",
    style: {
      margin: isMobileOnly ? "10.5% -27.5%" : "10.5% -27%",
    },
    // hotspotType: HotspotType.Notice,
    // link: "/photo-axis/noteCloud/index.html",

    hotspotType: HotspotType.anchor,
    link: "https://unstop.com/awards/prestigious-b-school-competitions-voting",
  },
  PhotoMosaic: {
    id: "PhotoMosaic",
    enabled: false,
    style: {
      margin: "10% 3%",
    },
    hotspotType: HotspotType.iframe,
    link: "https://photomosaic3.djvirtualevents.com",
    name: "PhotoMosaic",
  },
  SocialWall: {
    id: "SocialWall",
    enabled: false,
    style: {
      margin: "10% 10%",
    },
    hotspotType: HotspotType.iframe,
    buttonType: HotspotButtonType.named,

    // link: "https://my.walls.io/flipkart?nobackground=1&amp;show_header=0",
    name: "SocialWall",
  },
};

export let NetworkingLounge = {
  speakerPdf: "/web/viewer.html?file=%2Fassets%2Fcontent%2FSpeakers.pdf",
};

export const imagesData = {
  Consumer: {
    title: "Consumer Products",
    bgSize: "cover",
    arr: [
      {
        title: "L'ORÉAL PARIS",
        path: "/galleryImages/Consumer/one.jpg",
        label: "/galleryImages/Brands/loreal.svg",
        heading:
          "As the top beauty Brand sold in retail outlets, L’Oréal Paris makes the most innovative products accessible to everyone. Its ambassadors embody a certain beauty ideal summed up in the legendary signature: “Because you’re worth it.”",
        desc: `With 50 products sold every second worldwide, L'Oréal Paris offers from all continents male and female beauty products of all types (makeup, skin care, hair care, styling, hair color and men), from the excellence of its Research Laboratories. The worldwide success of legendary franchises such as Color Riche lipstick, Revitalift skin care, Elnett hairspray, Elsève hair care, Preference hair color or Men Expert Hydra Energetic skin care for men, reflect the brand’s unique expertise from the greatest beauty experts. Including Christophe Robin for hair color, Stéphane Lancien for hair care and styling and Val Garland for make-up./n/n L'Oréal Paris shares a unique vision of beauty, supported by 35 diverse international ambassadors, icons such as Jane Fonda, Eva Longoria, Julianne Moore, Elle Fanning, Helen Mirren, Maria Borges, Doutzen Kroes or Gong Li. The exceptional careers and charismatic personality of L’Oréal’s ambassadors resonate in the motto "Because you're worth it", a truly universal message of empowering beauty for the last 40 years. Each year, L'Oréal Paris magnifies its beauty ambassadors and worldwide celebrities from nearly 20 international red carpets, including the Cannes Film Festival, for which L’Oréal Paris has been the Official Makeup Artist for the last 20 years. The Film Festival provides the constantly renewed opportunity to highlight the superiority and expertise of its products accessible to every consumer. L’Oréal Paris is also the official partner of the Paris Fashion week, which allows the brand to be at the edge of beauty trends and to support well-known designers as well as the younger creative scene./n`,
      },
      {
        title: "GARNIER",
        path: "/galleryImages/Consumer/two.jpg",
        label: "/galleryImages/Brands/garnier.svg",
        heading:
          "Hair and skin experts since 1904, Garnier blends active natural ingredients to offer all active men and women the healthy good looks that help them connect with others.",
        desc: `Second largest brand of the L’Oréal Group, Garnier is an international haircare and skincare brand with sub-brands in four categories and seven areas of expertise – among which Ultra DOUX, Fructis, SkinActive, Ambre Solaire, Nutrisse or Olia. With products formulated to meet the needs of every man and woman, everywhere in the world, we offer innovative, affordable care solutions at the best prices./n/n

        Founded in France in 1904 by Alfred Amour Garnier, the brand was born with the launch of the first patented plant-based hair lotion, La Lotion Garnier. Over the decades Garnier expanded into skincare and cemented its reputation as the healthy beauty expert, by providing nature-based solutions to combat pollution, acne, UV and fatigue./n/n
        
        Garnier is renowned for its know-how in harnessing the bounty of nature– fruits, seeds, buds and flowers with highly beneficial skincare and haircare properties. Their active ingredients are extracted using the brand’s cutting-edge expertise and techniques to take full advantage of nature’s energy, effectiveness and generosity./n/n
        
        Garnier brings beauty to vast numbers of consumers while also reducing our ecological impact.The brand is committed to sustainable sourcing, to constantly reducing the environmental impact of our packaging and continuously seeking to improve the ecological profile and biodegradability indices of our formulas./n`,
      },
      {
        title: "MAYBELLINE NEW YORK",
        path: "/galleryImages/Consumer/three.jpg",
        label: "/galleryImages/Brands/mayblline.svg",
        heading:
          "Maybelline New York is the world’s leading cosmetics brand, available in over 100 countries. By combining technologically advanced formulations with on-trend expertise and New York City edge, Maybelline New York’s mission is to offer innovative, accessible, and effortless cosmetics for every woman.",
        desc: `1915 – Mabel Williams, a young woman from Chicago, was looking for a way to enhance her eyes. With an eye for ingenuity, she stirred together coal dust and Vaseline and applied the mixture to her lashes and brows. Inspired by the potential of her homespun invention, her brother Thomas Lyle Williams created Maybell Laboratories and began selling mascara through the mail. Women all over the US were soon clamoring for Maybelline, and in the 1930s the company responded by selling to local variety stores. From then on, the range of legendary products grew./n/n

        In 1971, after half a century of continuous innovation, Maybelline made waves in the cosmetic world by introducing the revolutionary, water-based Great Lash Mascara in its signature pink and green packaging. Since then it has become one of the most sold beauty products in the world and an absolute favorite among celebrities and makeup artists. Today, Maybelline New York leads the mascara category with its iconic Volum’ Express line: The Colossal was an instant worldwide hit, and shortly after its launch in 2010, The Falsies became the world’s #1 mascara./n/n
        
        A groundbreaking face innovation came in 2004 with the launch of Dream, a lineup of foundation, blush, bronzer, and concealer. Dream Matte Mousse, with its air-soft formula, is the #1 foundation worldwide today. In 2009, Maybelline New York transformed the category again with the launch of its first BB cream in Asia. Since then, millions of women around the globe have fallen in love with the unique formula of Dream Fresh BB 8-in-1 Skin Perfector. In the lip category, Maybelline New York introduced the most comprehensive color collection at mass with Color Sensational in 2009. The large array of shades is divided into four color families which allows women to easily identify their desired color. A major hit with its creamy, crisp colors, the range continues to deliver bold shades in tune with today’s trends. The latest success from Maybelline New York was the introduction of Color Show Nail Lacquers in 2012. The range of edgy textures, colors, and effects inspired by New York’s runways made fashion’s must-have nail looks accessible to all./n/n
        
        2009 marked a fashion milestone for Maybelline New York. After Amsterdam, Berlin and Moscow, the brand secured the coveted title of Official Makeup Sponsor of Mercedes-Benz Fashion Week in its hometown of New York City. Since then, Maybelline has been showcasing its creativity and expertise on the runways, collaborating with fashion designers to develop next season’s beauty trends. Today, Maybelline New York is proud to be the official makeup sponsor of thirteen fashion weeks worldwide./n/n
        
        With the glamour and energy of New York City, the ultimate makeup innovations, and the latest fashion looks from the catwalk to the sidewalk, Maybelline New York empowers women all over the world to express their personal “it” factor./n`,
      },
      {
        title: "NYX PROFESSIONAL MAKEUP",
        path: "/galleryImages/Consumer/four.jpg",
        label: "/galleryImages/Brands/nyx.svg",
        heading:
          "NYX Professional Makeup is a modern, digitally native makeup brand on the forefront of today’s emerging beauty trends. A leader in the global color cosmetics industry, NYX Professional Makeup is an affordable professional makeup line with every shade, color and tool needed to create makeup artistry at every level.",
        desc: `Rooted in a professional artistry heritage, NYX Professional Makeup boasts a successful multi-channel approach at the forefront of digital and retail. A social media pioneer, NYX Professional Makeup is one of the most influential and top-ranked brands, and is followed by top beauty vloggers, Instagram stars, and their millions of fans./n/n

        While headquartered in the heart of Los Angeles, NYX Professional Makeup is a global brand available in over 70 countries at thousands of retailers consisting of specialty beauty and fashion stores, freestanding shops, and beauty supply stores as well as on www.nyxcosmetics.com./n`,
      },
      {
        title: "STYLENANDA",
        path: "/galleryImages/Consumer/five.jpg",
        label: "/galleryImages/Brands/stylenanda.svg",
        heading:
          "Stylenanda began as a fashion business and has since become a company spearheaded by its make-up brand 3CE which today represents more than 70% of the business. With a turnover of 127 million euros in 2017 and nearly 400 employees, the company now operates in Korea and Japan, and has expanded its business into overseas markets including Hong Kong, Singapore, Malaysia and Thailand.",
        desc: `Stylenanda is a highly desirable brand among Korean and Chinese millennials. Stylenanda's multi-channel distribution model includes e-commerce, speciality retailers, point of sales in department and duty free stores. In addition, Stylenanda aficionados can also fully live the consumer and brand-selfie experience in the company's iconic flagship locations: Stylenanda Hong-dae, Myeong Dong Pink Hotel & Pink Pool, Garosu-gil 3CE cinema and Stylenanda Harajuku in Tokyo.`,
      },
      {
        title: "ESSIE",
        path: "/galleryImages/Consumer/six.jpg",
        label: "/galleryImages/Brands/essie.svg",
        heading:
          "This iconic American brand allows women all over the world to express themselves with unique and whimsical color names. With unbeatable, professional quality, Essie nail polish sets the standards for celebs and women around the world alike!",
        desc: `The USA’s nail salon expert since 1981, essie aims to inspire a love for the manicure experience with a wit and style that touches the hearts of women everywhere. essie is the go-to nail brand for beauty professionals, industry insiders, celebrities and fashion icons around the world. as the color authority, essie is center stage on runways around the world, offering highly-anticipated color collections that drive trends season after season. the salon-quality formula and catchy, whimsical names has led to the creation of more than 1,000 shades. essie is a leader in nail luxury, committed to high-quality standards with an award-winning line of polishes and nail care products for every woman and salon professional. Since its introduction by Essie Weingarten, essie has delighted the color-obsessed with a love shared all over the world./n/n

        This iconic love brand allows women all over the world to express themselves with unique and whimsical polish colors. essie has recently grown its brand with launches of the long-wear gel couture and the care + color line of TREAT LOVE & COLOR. with unbeatable, professional quality, the essie love continues to grow as strong as ever./n`,
      },
      {
        title: "DARK AND LOVELY",
        path: "/galleryImages/Consumer/seven.jpg",
        label: "/galleryImages/Brands/d-l.svg",
        heading:
          "Our consumers are the source of our inspiration as it is almost impossible to find a wider scope of beauty rituals amongst any other consumer group: from braiding to weaving from relaxing to treating from coloring to styling.",
        desc: `Therefore, all the beauty products developed by our laboratories are specifically dedicated, adapted and efficient to perfectly meet their demanding beauty needs./n/n

        To be the world leader in afro-specific hair care means all our energy is focused on delivering state of the art hair care technologies. And to achieve this, the Advanced Research of our unique dedicated team of scientists and biologists based in South Africa have developed extensive knowledge in collaboration with African hair stylists & dermatologists. Making us the experts of consumers of African descent on a worldwide scale./n/n
        
        International brands such as Dark and Lovely and Magic, or local brands such as Nice & Lovely, Restore Plus and Blue Ice are all part of the African Beauty Brands portfolio./n/n 
        
        Pioneer on its market, African Beauty Brands are responsible for several major innovations:/n/n
        
        1901: Magic Shaving Powder was the first men’s depilatory brand/n/n
        
        1972: Dark and Lovely launched the first permanent liquid hair color designed for the demanding hair of African American women/n/n
        
        1978: Dark and Lovely launched the first no-lye relaxer kit to relax hair at home with no risk of scalp irritation/n/n
        
        1993: Dark and Lovely Beautiful Beginnings: the first relaxer specially designed for the sensitive scalp of girls/n/n
        
        2012: Dark and Lovely Color Intensity: the first hair color cream tailor-made for African hair/n/n
        
        African Beauty Brands: beauty without compromise./n`,
      },
      {
        title: "MIXA",
        path: "/galleryImages/Consumer/eight.png",
        label: "/galleryImages/Brands/mixa.svg",
        heading: "A product popular with the whole family",
        desc: `When created in 1924 by a pharmacy doctor, Mixa was the combination of a cream and a powder to fight against redness.
        Today, the brand is the expert in caring for all types of sensitive skin./n/n
        
        From infancy to adulthood, Mixa cares for all women on a daily basis and in all stages of their life thanks to affordable skincare products sold in supermarkets./n
        Mixa Expert Peaux Sensibles skincare products are formulated under medical supervision and comply with a strict formulation charter combining effectiveness and tolerance, using caring active ingredients produced by pharmacists with clinically proven effectiveness and hypoallergenic formulae, and tested on more than 7,000 sensitive skins./n/n
        
        Mixa: Simple. Effective. Safe. In 2011, Mixa created the face cream for sensitive skin: a product dedicated to fragile, dehydrated, and tight skin. A travel-friendly cream, it is suitable for the whole family, even the delicate skin of babies./n/n

        Simple: its hypoallergenic formula is enriched with sweet almond oil. Effective: it provides 24-hour moisturization, prevents dryness, and improves the skin's barrier function. Safe: the cream has been tested on more than 7,000 sensitive skins and formulated under medical supervision./n/n
        
        Every year, 400,000 tubes are sold. And since 2019, the face cream for sensitive skin is available in a certified organic version./n`,
      },
      {
        title: "MAGIC",
        path: "/galleryImages/Consumer/nine.jpg",
        label: "/galleryImages/Brands/mg.svg",
        heading:
          "Magic Mask, the leading brand of the mask industry in China was founded in 2003, and has already accompanied Chinese women towards their beauty transformation for over ten years.",
        desc: `Since the milestone slogan, “Take a Break, Enjoy your Magic Moment”, MG has encouraged women to perceive the unique beauty of life, enjoy their own peaceful moment with 15-minute maskcare. Also, MG innovatively launched the first Single-sheet Sales Model in China, as well as developing hundreds of mask products to provide Chinese women with great variety of mask options. As a Mask Expert, MG always keeps its professional and innovative spirit. In 2014, MG Mask joined L'Oréal, and then set up the first Mask Research and Development Centre in China. With the support of advanced skincare technology from L’Oréal, MG invented several breakthrough products, including MG Water Glow Mask, MG Black Ferment Mask, MG Super Seeds Mask, MG Sensitive Skin Mask and Fresh-mix Mask./n/n

        MG Mask has been committed to become founder and trend-setter of mask culture with unparalleled expertise on this field. Also, MG mask will continually formulate with legendary natural ingredients carefully selected from China, in order to provide customers with instant & lasting proven beauty results. Known as the Mask Expert, MG Mask will continue to lead the innovation of Chinese facial mask industry and start the new era of Committed Natural Maskcare./n`,
      },
    ],
  },
  Professional: {
    title: "The Exciting World of Reliance",
    description: "Click on each tab to know more",
    bgSize: "cover",
    arr: [
      {
        title: "Retail",
        path: "/galleryImages/Business/R_Retail.jpg",
        label: "/galleryImages/Brands/loreal-p.svg",
        link: "https://www.ril.com/ar2020-21/retail.html",
        hover_data: {
          title: "",
          description:
            "India’s largest retailer by reach, revenue and profitability. Only Indian retailer to feature in the list of Global Powers of Retailing.",
        },
        heading:
          "As the global leader of the professional hairdressing market, our mission is to elevate every day the professional Hair Artistry through great services and products, bespoke education and a true digital transformation for a memorable customer experience. Throughout the world, we want to empower hair artists to unleash the star in every woman.",
        desc: `Our DNA. We believe in superior quality and innovation in services and products. Right from the start, Eugène Schueller used his genius to propose reliable solutions to meet hairdressers’ needs. He created in 1909 the first harmless hair dye, called Oréal. Our ground-breaking products are tested and approved by the most famous hairdressers and we never stop trying to maintain the highest quality standards of performance, comfort, security and sustainability./n/n

        Our passion. We want to inspire hairdressers with the latest trends – and a Parisian touch! We support many fashion shows in the world and strive to be THE reference for hair trends. From fashion catwalks to celebrity looks or hair trends spotted in the street, we are always tuned to the latest trends to help hairdressers create all the looks that women are craving for./n/n
        
        Our commitment. Because we believe in professional gestures and tools that make the difference, we are determined to help hairdressers in their daily practice by providing best-in-class education, teaching the latest hairstyle techniques, developing services to highlight their expertise and improving their work conditions. To do so, we have created a 15 minute daily program, “L’Oréal Professionnel with you against MSDs”, to fight Musculo-Skeletal Disorders, which represent 75% of professional diseases./n/n
        
        Our ambition. We want to pursue and reinforce this fantastic partnership that has been uniting the brand and hairdressers for over a century – and always do so in a responsible way, both sustainable and generous. Our dream is to continue serving hairdressers to build a long-term global leadership that benefits to all./n/n
        
        Our star products. Inoa, Majirel, Smartbond, Série Expert, Pro Fiber, Serioxyl, Mythic Oil, X-Tenso, Steampod, Tecni.art, Infinium./n/n`,
      },
      {
        title: "Digital Services",
        path: "/galleryImages/Business/R_Digital_Services.jpg",
        label: "/galleryImages/Brands/redken.svg",
        link: "https://www.ril.com/ar2020-21/digital-services.html",
        hover_data: {
          title: "title",
          description:
            "Through Jio Platforms Limited (JPL), Reliance operates India’s largest telecom network.",
        },
        heading:
          "Redken is known for providing inspiring, fashion-forward and innovative products and services and an industry-leading education platform to the professional industry for more than 50 years. The leader in the US, Redken brings NYC style and creativity to the worldwide salon community with a commitment to help every salon professional “learn, earn and live better.”",
        desc: `INSPIRATION. FASHION. INNOVATION./n/n

        Founded in 1960 by a dynamic, entrepreneurial American actress, Paula Kent, and her hairdresser, Jheri Redding (thus the name "REDKEN"), Redken's foundation is still based on extensive scientific knowledge and the belief in, and support of, the expertise of the salon professional. Redken is committed to help every salon professional "learn, earn and live better" and supports this goal not only with innovative products, but also with the inspiration provided by an industry-leading platform of education programs./n/n
        
        In 1993, L'Oréal purchased Redken and moved the company to 5th Avenue NYC, where the brand developed a strong presence backstage with top designers at Fashion Week. Today, Redken is known as a trendsetting urban brand and is a global leader in professional haircare products and services. The company is fueled by talented people, strong partnerships with the best hairdressers, and superior Research and Innovation./n/n
        
        Star products/n/n
        
        Haircolor: Shades EQ, Color Fusion, Chromatics, Blonde Idol, City Beats/n/n
        
        Haircare: All Soft, Color Extend, Color Extend Magnetics, Extreme, Frizz Dismiss/n/n
        
        Styling: Pillow Proof Blow Dry, Rewind 06, Guts 10, Quick Dry 18, Control Addict 28/n/n
        
        Redken products are available in professional beauty salons in over 50 countries./n`,
      },
      {
        title: "Media & Entertainment",
        path: "/galleryImages/Business/R_Media_Entertainment.jpg",
        label: "/galleryImages/Brands/kerastase.svg",
        link: "https://www.ril.com/ar2020-21/media-and-entertainment.html",
        hover_data: {
          title: "title",
          description:
            "One of India’s largest media houses with omni-channel presence, bringing the world to households.",
        },
        heading:
          "In a world in which hair routine was purely about hygiene, Kérastase invented care for hair in 1964. Since then, the brand has been offering women and men the best of professional haircare. The brands partners with hairdressers from the most high-end salons to create together the ultimate formulas, products and professional services for amazing results. ",
        desc: `Kérastase created with hairdressers based on their expertise and experience with clients, a unique hair diagnosis in order to understand each women’s individual hair needs./n/n

        At Kérastase there is one belief: there is not one single interpretation of beauty but an infinity. The brand enhance all types of beauty, cultures, visions… and of course, all types of hair./n/n
        
        Part of a wider influential community, Emily Ratajkowski embodies Kérastase’s vision of beauty; with her unapologetic and modern stance on femininity./n/n
        
        In 2018, Kérastase celebrates its community by creating the Kérastase Club gathering the best hairdressers and the most inspiring women, from artists to athletes and entrepreneurs. Through their salons, their Instagram and brand events, Kerastase Club community aims to share their expertise and passion for hair all around the world!/n`,
      },
      {
        title: "Oil to Chemicals",
        path: "/galleryImages/Business/R_O2C.jpg",
        label: "/galleryImages/Brands/matrix.svg",
        link: "https://www.ril.com/ar2020-21/oil-chemicals.html",
        hover_data: {
          title: "title",
          description:
            "One of the world’s most integrated Oil to Chemicals operations, driving India’s energy security.",
        },
        heading:
          "Matrix is one of the leading American professional brand worldwide. Matrix was founded in 1980 by the American husband and wife hairdressing team, Arnie and Sydell Miller. The Millers wanted to provide hairdressers with a comprehensive range of products that would help them grow their businesses and provide the means to take full advantage of their talent and creativity.",
        desc: `At Matrix we are wholly dedicated to hairdressers. Our mission is to be the brand that unites hairdressers and bring the best out of them. We believe we are stronger together./n/n

        Our vision is to build and unite the largest and best educated global community of hairdressers fueled by expressive inspiration, uncomplicated products and services for all./n/n
        
        Among our values are:
        
        Inclusive: Open to everyone, not limited to certain people, including the stated limits and everything in between./n/n
        
        Collaborative : working with trusted team to encourage new thoughts and innovation/n/n
        
        Authentic : in voice and visage, our visuals and promises are honest and true,/n/n
        
        Uncomplicated : not simple, but smart and straight forward, easy to understand and put in practice/n/n
        
        Expressive : we are chilled, and show emotions and feelings clearly and openly/n/n
        
        Sustainable : our commitment is to minimize our impact on the planet from our offices to the salons we work with/n/n
        
        At the forefront of salon education, Matrix has been elected the No.1 education team in the USA and trains thousands of hairdressers around the world delivering the best education in person and through new technologies such as Virtual Reality and E-Learning./n/n
        
        Through our social media channels we inspire the community of hairdressers with strong color emotion visuals and techniques created by our Global Design Team of International Matrix Artists./n/n
        
        Around the world we are committed to work together to elevate hairdressers and the whole salon industry, from the USA to Russia and India. And ultimately bring transformative beauty looks to our consumers./n/n
        
        The brand offers a wide range of hair care, hair color and hair texturizing products including: Total Results, SoColor, Color Sync, Style Link and Opti.Smooth./n`,
      },
      {
        title: "Exploration & Production",
        path: "/galleryImages/Business/R_E&P.jpg",
        label: "/galleryImages/Brands/pureology.svg",
        link: "https://www.ril.com/ar2020-21/oil-gas-exploration-production.html",
        hover_data: {
          title: "title",
          description:
            "Upstream portfolio consisting of deep water acreage and CBM blocks in India and Shale Gas in the United States of America.",
        },
        heading:
          "Pureology is the specialist in caring for color-treated hair. Acquired in 2007, the brand pioneered the “no nasties” movement and has gained a devoted following through its exclusive ZeroSulfate® shampoos, vegan formulas and powerful AntiFadeComplex®. With a growing passion and commitment to sustainability, the brand is seeing rapid growth globally.",
        desc: `Founded in 2001 in Orange County, California, Pureology began with a simple idea: To create the very best care for color-treated hair. Since then, the brand has become the authority in color care and has inspired a devoted following with its promise to create a safe haven for color treated hair./n/n

        Created specifically for professional colorists and their clients, Pureology is a brand unlike any other. Highly concentrated ZeroSulfate® shampoos are entirely free of harsh color-stripping ingredients, while the exclusive AntiFadeComplex® helps color-treated hair retain its fresh-from-the-salon vibrancy with every use. 100% Vegan formulas are infused with natural plant extracts, while Dual Benefit systems including Hydrate, Clean Volume, Strength Cure, Smooth Perfection, Fulfyl and more offer custom care for every hair type./n/n
        
        And because caring for color-treated hair doesn’t stop when styling begins, Pureology Color Stylist offers a complete range of stylers formulated to boost shine, prevent color fade and enhance haircolor’s beauty. With Pureology, clients no longer have to choose between the results they want and the color care they deserve./n/n
        
        Pureology is highly committed to sustainability and supports sustainability causes through various global associations around the world./n`,
      },
    ],
  },
  Active: {
    title: "Life at Reliance",
    description: "Click on each tab to know more",
    bgSize: "contain",
    arr: [
      {
        title: "R-Foundation",
        path: "/galleryImages/Initiative/R_FD.jpg",
        label: "/galleryImages/Brands/ceraVe.svg",
        link: "https://careers.ril.com/frmlifeatrelianceN.aspx",
        hover_data: {
          title: "title",
          description:
            "Moving well beyond our business activities, we strive to create a positive societal impact through diverse community engagement initiatives.",
        },
        heading:
          "CeraVe is the dermatologist-recommended skincare brand that contains three essential ceramides to restore skin’s natural barrier. Since the creation of the brand in the USA in 2005, every CeraVe product has been developed with leading dermatologists. The brand now offers a range of skincare products, especially cleansers, moisturizers and healing ointments. The products are highly effective and non-irritating, while remaining accessible, affordable and simple. They answer various skin issues that affect quality of life.",
        desc: `The success of CeraVe is based on formulas combining 3 essential ceramides (ceramides 1, 3 et 6-II). Ceramides are lipids (oils) found naturally in skin and are essential in supporting the skin barrier and retaining moisture. Without ceramides, skin can become dry, itchy, or irritated. Ceramide loss or altered composition are associated with numerous skin concerns, which is why CeraVe is positioned to address a variety of skin issues ranging from dry skin to eczema and acne./n/n

        Thanks to a revolutionary delivery patented technology, the Multivesicular Emulsion (MVE), which releases continuously active moisturizing ingredients, the hydration is sustained during the day with just one use./n/n
        
        CeraVe is one of the fastest growing skincare brand in the United States . More than 20 CeraVe products have been recommended by the National Eczema Association. Among them, the Moisturizing cream is the most recommended products by dermatologist in the US ./n/n
        
        CERAVE SKINCARE FOR ALL/n/n
        
        CeraVe’s mission is to develop formulas with dermatologists that help replenish, restore, and optimize the skin barrier while delivering elegant texture experiences for users around the world./n`,
      },
      {
        title: "Spectrum",
        path: "/galleryImages/Initiative/Spectrum_logo.jpg",
        label: "/galleryImages/Brands/la-roche.svg",
        link: "https://careers.ril.com/frmlifeatrelianceN.aspx",

        hover_data: {
          title: "",
          description:
            "A unique annual initiative offering the employees with a plethora of Learning & Development opportunities.",
        },
        heading:
          "Recommended by 25,000 dermatologists worldwide, La Roche-Posay Laboratoire Dermatologique develops skin care products with La Roche Posay Thermal Spring water for a better life for sensitive skin.",
        desc: `Public utility since 1897, the thermal spring water from La Roche-Posay, naturally antioxidant, soothes and softens the skin with a unique concentration of selenium. Its clinically proven results have made it today, the world’s leading center of thermal dermatology./n/n

        For over 40 years, La Roche-Posay has worked with dermatologists throughout the world to create skincare and make-up products that meet their needs and requirements./n/n
        
        La Roche-Posay is a reference in the development of minimalist formulas dedicated to sensitive skin. A rigorous selection of beneficial concentrated active ingredients is carried out, excluding any potentially irritating ingredients. Formulas are also paraben-free, fragrance-free and 100% allergy-tested. La Roche-Posay products are dermatologist tested on atopic or reactive skin allergies. Results are shown in clinical tests and trials./n/n
        
        Since its founding in 1995, La Fondation La Roche-Posay has awarded more than 160 grants for dermatology research on four continents. The brand is also engaged, in partnership with dermatologists, in several awareness campaigns on the prevention of skin cancer./n/n
        
        The most popular product ranges are: Lipikar (dry skin), Anthélios (photoprotection), Effaclar (acne), Tolériane (sensitive skin) and Cicaplast (skin repair)./n/n
        
        La Roche-Posay, a better life for sensitive skin./n`,
      },
      {
        title: "Step-Up",
        path: "/galleryImages/Initiative/Step_Up.jpg",
        label: "/galleryImages/Brands/vichy.svg",
        link: "https://careers.ril.com/frmlifeatrelianceN.aspx",
        hover_data: {
          title: "title",
          description:
            "A highly specialized program to prepare high potential employees for their transition to next-level leadership roles.",
        },
        heading:
          "Since Dr Haller founded Vichy Laboratoires in 1931, the brand has been offering women products which guarantee security and efficacy for sensitive skins.",
        desc: `Vichy thermal Mineralizing Water, born in the French volcanoes of Auvergne and charged with 15 rare minerals is proven to strengthen skin against aggressions such as UV, pollution and stress./n/n

        Vichy thermal Mineralizing Water is at the base of all our Vichy formulations. All of our skin care products are co-developed with health professionals such as dermatologists and pharmacists and integrate only allergy-tested ingredients inspired by nature and dermatology in formulas that are efficacious and suitable for sensitive skin./n/n
        
        Thanks to this combination of Vichy thermal Mineralising water and its specific approach to formulation and evaluation, Vichy proposes to women, products which can give them a radiant skin health./n/n
        
        A new star product, has recently been added to Vichy’s extensive product catalogue, Minéral 89 hydrates, plumps and fortifies skin boosting its’ resilience against stress, pollution and fatigue. Slow Age with its new approach to anti-age, to slow down the appearance of aging signs and correct them as they start to form, Liftactiv, the first care with a daily lifting effect for a continuing wrinkle firm correction; Neovadiol, leader of Menopause cosmetics in the Pharmacy, increases the skin’s density and remodels the skin’s volumes; Idealia, a programme of skincare which corrects the skin’s quality; Aqualia Thermal, which capitalises on Vichy thermal water to hydrate and soothe sensitive skin; Dercos with its haircare proposal, introduced over 15 years ago, remains a pioneer on the anti-hair loss market./n`,
      },
      {
        title: "R-University",
        path: "/galleryImages/Initiative/R_University_Logo.jpg",
        label: "/galleryImages/Initiative/R_University_Logo.jpg",
        link: "https://careers.ril.com/frmlifeatrelianceN.aspx",
        hover_data: {
          title: "title",
          description:
            "RIL’s capability building vehicle that focuses on building both technical & functional capabilities with behavioural & leadership capabilities.",
        },
        heading:
          "SkinCeuticals mission: to provide advanced skincare back by science to prevent visible signs of aging, protect healthy skin, and correct the appearance of aging. Used by leading dermatologists and plastic surgeons worldwide.",
        desc: `SkinCeuticals is the #1 US medical aesthetic skincare brand worldwide . An early pioneer of cosmeceuticals  in the US, SkinCeuticals innovates critical anti-aging breakthroughs at the forefront of antioxidant technology. Evidence proving skin absorption and antioxidant benefits of our vitamin C formulations is based on the academic research of Dr. Sheldon Pinnell , a renowned American research dermatologist from Duke University and SkinCeuticals founding scientist. With cutting-edge in-vivo (in live skin) science as the foundation of our existence, SkinCeuticals has been providing advanced skincare to medical professionals since 1997./n/n

        The SkinCeuticals skincare regimen philosophy centers on three main pillars:/n/n
        
        - Prevent visible signs of aging with high-potency antioxidants by neutralizing damaging free radicals caused by atmospheric aggressors, such as UVA/UVB, infrared (IR-A), and ozone pollution/n/n
        
        - Protect healthy skin against harmful UVA/UVB rays with high-protection sunscreens/n/n
        
        - Correct the visible signs of existing damage with targeted solutions/n/n
        
       SkinCeuticals believes the key to optimal skin health is a skincare professional, who can customize an at-home regimen with powerful in-office treatments to achieve a visible improvement of fine lines, wrinkles, and other signs of aging for an overall healthier, more radiant appearance./n`,
      },
      {
        title: "R-Swasthya",
        path: "/galleryImages/Initiative/R_Swasthya_logo.png",
        label: "/galleryImages/Brands/decleor.svg",
        heading: "Decléor, skincare powered by essential oils.",
        link: "https://careers.ril.com/frmlifeatrelianceN.aspx",
        hover_data: {
          title: "title",
          description:
            "Investing in the health of our employees and are committed to equip them in leading happier, healthier and more fulfilling lives.",
        },
        desc: `Decléor was created in 1974, long before the essential oils craze started./n/n

        What are essential oils? They are the purest fraction of a plant, endowed with powerful abilities. They are the volatile aromatic molecules made by the plant to self regenerate and heal./n/n
        
        Therefore, for 40 years, we’ve been fine-tuning our expertise, to create essential oil-infused skincare products, for all skin types./n/n
        
        Our credo? Craft extra powerful and concentrated formulae, using the best natural active ingredients, to combat the damage of urban living on skin (yes, that effect of lack of sleep, pollution, stress, and unbalanced diet)./n`,
      },
      {
        title: "R-Sammaan",
        path: "/galleryImages/Initiative/R_Sammaan_logo.jpg",
        label: "/galleryImages/Brands/decleor.svg",
        heading: "Decléor, skincare powered by essential oils.",
        link: "https://careers.ril.com/frmlifeatrelianceN.aspx",
        hover_data: {
          title: "title",
          description:
            "Providing a social platform to reward colleagues across the organization, irrespective of their functions, departments and locations.",
        },
        desc: `Decléor was created in 1974, long before the essential oils craze started./n/n

        What are essential oils? They are the purest fraction of a plant, endowed with powerful abilities. They are the volatile aromatic molecules made by the plant to self regenerate and heal./n/n
        
        Therefore, for 40 years, we’ve been fine-tuning our expertise, to create essential oil-infused skincare products, for all skin types./n/n
        
        Our credo? Craft extra powerful and concentrated formulae, using the best natural active ingredients, to combat the damage of urban living on skin (yes, that effect of lack of sleep, pollution, stress, and unbalanced diet)./n`,
      },
    ],
  },
  Luxe: {
    title: "L’Oréal Luxe",
    arr: [
      {
        title: "LANCÔME",
        path: "/galleryImages/luxe/one.jpg",
        label: "/galleryImages/luxe/one-brand.svg",
        heading:
          "Founded in 1935 by the visionary pioneer Armand Petitjean, with the wish of spreading the spirit and taste of French elegance around the world, Lancôme immediately embodied the essence of beauty. Today more than ever, the brand aims to offer every woman the possibility to blossom and embrace her beauty and femininity whatever her age or skin color.",
        desc: `Today the brand is present in approximately 130 countries, with about 20,000 beauty advisers in as many points of sale offering sophisticated services and iconic products to an international clientele. Lancôme successfully combines scientific expertise and an intuitive understanding of women's needs to produce major innovations, unique and complementary between skincare, makeup and perfume (La vie est belle, Trésor, L’Absolu Rouge, Advanced Génifique, Visionnaire, Teint Idole Ultra Wear, Grandiôse, etc)./n/n

        Lancôme affirms that happiness is the most attractive beauty. It’s a skin deep emotion, awakening every sense. Day after day, its ambition is that every woman who comes to Lancôme to be more beautiful will leave happier. The brand has chosen charismatic and fully accomplished women to be its ambassadresses: women with heart. Julia Roberts, Kate Winslet, Penélope Cruz, Lupita Nyong’o, Lily Collins, Taylor Hill and Isabella Rossellini are all beautiful icons who love life, who are full of energy, and talent; icons every woman can relate to. Lancôme is the mirror image of these women who embody a radiant, confident and universal beauty while keeping it modern and down to earth./n/n`,
      },
      {
        title: "YVES SAINT LAURENT",
        path: "/galleryImages/luxe/two.jpg",
        label: "/galleryImages/luxe/two-brand.svg",
        heading:
          "Yves Saint Laurent Beauté is the makeup, fragrance and skincare signature of the most subversive Parisian couture brand. No compromise. Your own rules. Now.",
        desc: `The Yves Saint Laurent history of fragrances is punctuated by successes. Ever since the 1964 creation of « Y », more than 50 years ago, YSL Beauté has produced true olfactory sociological markers. A societal dimension perfectly illustrated by the image of Monsieur Yves Saint Laurent posing nude for the launch of his first men's fragrance, Pour Homme, in 1971. Today, many fragrances of the brand have become must-have references both for men, with L'Homme, La Nuit de l'Homme and women, with Opium, Black Opium, Paris and Mon Paris./n/n

        Makeup offers the highest technology at the heart of a precious case, under the inspiration of one of the most famous makeup artist Tom Pecheux, newly appointed Global Beauty Director. The YSL Beauté makeup icons include Touche Éclat, Mascara Volume Effet Faux Cils, Rouge Pur Couture and Rouge Pur Couture Vernis à Lèvres glossy stain./n/n
        
        Skincare unites the most cutting-edge technology with glamour and an ultimate sensorial experience. Top Secrets, a pioneering skincare range for makeup lovers that presents a simple beauty equation: skincare plus makeup equals a multitasking hybrid of pre and post-makeup solutions for all complexions. The skincare branch of the brand is today conquering the premium skincare territory with its Or Rouge collection that brings together the Glycobiology science and a precious ingredient containing a high concentration of glycans, the pistil of Saffron./n`,
      },
      {
        title: "GIORGIO ARMANI",
        path: "/galleryImages/luxe/three.jpg",
        label: "/galleryImages/luxe/three-brand.svg",
        heading:
          "From Haute Couture to designer beauty, the Giorgio Armani signature is comprised of essential pieces made from exceptional materials, and the belief that beauty and practicality enrich each other.",
        desc: `Giorgio Armani fragrances are constructed around a simple fragrance concept, interpreting the world’s most precious ingredients through the creative imagination of the most talented perfumers. Some celebrate nature, like the mythical Acqua di Giò, and its feminine counterpart, Acqua di Gioia; while Code Man and Woman explore the always fascinating territory of seduction. The brand’s latest launch, Sὶ, is the revelation of sophisticated Armani femininity. But it is through the exceptional expertise of the Armani/Privé collection that the designer finds his most “couture” expression. A vision of luxury fragrances, separated into three families: "Les Eaux," "La Collection" and "Les Mille et Une Nuits./n/n"

        Giorgio Armani makeup is meticulously developed and widely acclaimed by makeup artists worldwide for its constant innovation and has emerged as the undisputed master of color. Giorgio Armani is a must in foundation, with products such as the uncontestable Face Fabric and the revolutionary Maestro Fusion Makeup. From Crema Nera Extrema to Armani Prima, Giorgio Armani’s range of skin treatments uses the best of scientific research to capture the power of nature. Simplicity with attention to detail is the key to the designer’s very personal vision of sublime beauty; unique textures highlighting a final signature./n`,
      },
      {
        title: "KIEHL'S",
        path: "/galleryImages/luxe/four.jpg",
        label: "/galleryImages/luxe/four-b.svg",
        heading: "Naturally Inspired Skin Care, Body and Haircare.",
        desc: `Kiehl's was founded as an old-world apothecary in New York's East Village neighborhood over 166 years ago. Its unique and extensive background represents a blend of cosmetic, pharmaceutical, herbal, and medicinal knowledge developed and advanced through the generations./n/n

        Since 1851, Kiehl’s has been committed to giving back to the community where it serves its patrons. In fact, Kiehl's mission states that we are “to improve in some way the quality of the community… making for better citizens, better firms, and better communities.” To that end, Kiehl’s focuses its efforts first and foremost on the betterment of its local communities by targeting developing philanthropic initiatives that support Kiehl's three pillar causes - HIV/AIDS research, environmental issued and children's well-being./n`,
      },
      {
        title: "BIOTHERM",
        path: "/galleryImages/luxe/five.jpg",
        label: "/galleryImages/luxe/five-b.svg",
        heading:
          "A pioneer in skin biology since discovering the benefits of Life Plankton™ in 1952, Biotherm uses the incredible healing force of Life Plankton™ in advanced skincare solutions.",
        desc: `Through expertise in Biotechnology, the brand infuses the most powerful active ingredients in unparalleled pleasurable textures for a healthy and active beauty./n/n

        Biotherm offers a full range of over 130 face and body solutions, adapted for every skin type, concern and lifestyle. Iconic products include Life Plankton™ Essence, Aquasource Gel, Blue Therapy Serum and Lait Corporel./n`,
      },
      {
        title: "URBAN DECAY",
        path: "/galleryImages/luxe/six.jpg",
        label: "/galleryImages/luxe/six-b.svg",
        heading:
          "Urban Decay’s story opens in the mid-‘90s, when pink, red and beige enslaved the prestige beauty market. Heaven forbid you wanted purple or green nails, because you’d either have to whip out a marker, or risk life and limb with that back alley drugstore junk.",
        desc: `Flying in the face of this monopoly, Sandy Lerner, David Soward and Wende Zomnir unleashed Urban Decay in January of 1996 with a line of 10 lipsticks and 12 nail enamels. Inspired by seedier facets of the urban landscape, they bore groundbreaking names like Roach, Smog, Rust, Oil Slick and Acid Rain. The vibe since day one? Beauty with an edge™./n/n

        While UD has grown like crazy since then, they haven’t stopped experimenting. They now offer an endless array of high-performance cosmetics, from velvety-soft shadows and creamy lipsticks to weightless complexion products and high-tech setting sprays to make your makeup last all day./n`,
      },
      {
        title: "SHU UEMURA",
        path: "/galleryImages/luxe/seven.jpg",
        label: "/galleryImages/luxe/seven-b.svg",
        heading:
          "Created in 1983 by Shu Uemura, a pioneering Japanese makeup artist known worldwide, shu uemura has expanded internationally in three fields: makeup, skin care and professional accessories.",
        desc: `Led by Uchiide, international artistic director, shu uemura atelier’s team of artists embodies Shu Uemura’s holistic vision of beauty. They follow the founder’s legacy by constantly reinventing the brand with edgy modernity. Their mission is to build, connect with, and inspire their community of makeup addicts. From product development to artistic look creations, shu uemura atelier artists share their creativity and experience with the world./n/n

        Shu uemura makeup is packaged in an artistic palette containing the latest colors and textures, for both natural and more sophisticated looks. The products are simple but always unique. Each year, the brand releases coveted limited collections created in collaboration with renowned artists such as Karl Lagerfeld, Takashi Murakami, and Maison Kitsune./n/n
        
        Based on the philosophy of its founder that “beautiful makeup starts with beautiful skin”, the shu uemura cleansing ritual starts with its legendary oils. Both gentle and effective, they work in perfect harmony with the skin and naturally improve the skin’s condition for a beautiful makeup finish./n/n
        
        Tokyo makeup atelier - As the definitive professional makeup artist brand from Tokyo, shu uemura has designed its points of sale as interactive artist studios to share beauty knowledge while connecting with customers./n/n
        
        The brand’s iconic products include cleansing oil, hard formula, rouge unlimited, pressed eyeshadows, and eyelash curlers./n`,
      },
      {
        title: "IT COSMETICS",
        path: "/galleryImages/luxe/eight.jpg",
        label: "/galleryImages/luxe/eight-b.svg",
        heading: "Game-changing products, life-changing results!™",
        desc: `About IT Cosmetics

        Innovative Technology is IT. Developed with leading plastic surgeons and dermatologists, IT Cosmetics™ creates products that are game-changing and, when in the hands of real women everywhere, become life-changing. IT’s clinically proven, innovative, problem-solving products harness cutting-edge anti-aging technologies and skin-loving ingredients to truly give you the power to be Your Most Beautiful You™./n/n
        
        Our mission/n/n
        
        is to make the world more beautiful through our products, through our actions and through our belief that every woman is beautiful and deserves to feel her most beautiful. We work with plastic surgeons to create products that are game-changing and, when in the hands of real women everywhere, become life-changing./n
        
        Our promise/n/n
        
        is to always keep our promise whether it comes in a compact, a lipstick bullet or in our commitment to change the conversation about what is beautiful. We exist to inspire every women to celebrate her own beauty and to feel confident, important, loved and beautiful. Even if it’s for the first time. Or for the first time in a long time. If you believe in it you will see it, because it is bigger than ourselves. it is authentic and empowering. it is inspiring and magical. it is all around us and in every one of us. it is love. You are it./n
        
        Love Beauty Fully™/n/n
        
        We believe you’re an IT Girl™ the moment you try IT—and today, there is a thriving community of IT Girls, including IT Survivors. Through a partnership with the Look Good Feel Better® program, IT Cosmetics™ has donated millions of products to help women face the effects of cancer with confidence. When you purchase any Brow Power™ brow pencil and Love Beauty Fully™ brush, you are giving the gift of joy and beauty to another woman, and to yourself, and that is a beautiful thing! Together, we are making the world more beautiful, one product at a time./n`,
      },
      {
        title: "HELENA RUBINSTEIN",
        path: "/galleryImages/luxe/nine.jpg",
        label: "/galleryImages/luxe/nine-b.svg",
        heading:
          "In 1902, Helena Rubinstein revolutionized the world of cosmetics. True to this heritage, the brand continues to expand into new territories, drawing on the most advanced research areas, to create ultra-premium exceptional anti-aging treatments: clinical efficacy without any compromise on sensoriality.",
        desc: `In 1902, Helena Rubinstein revolutionized the world of cosmetics. Fascinated by the beauty and the power it gives to women, she approached the greatest scientists of her time to better understand and treat the skin. Starting from nothing, Helena Rubinstein created everything and built an empire: she invented the first moisturising cream called Valaze and the first beauty institute (1902), the first beauty treatment involving electricity (1907), the first categorization of types of skin (1910), the first hormone mask (1934), the first waterproof mascara (1939), the first preventive anti-ageing product (1956), the first automatic mascara (1958), the first pure Vitamin C based treatment (1995), the first lifting cream (1996), and many others innovations…/n/n

        True to this heritage, the brand continues to expand into new territories, drawing on the most advanced research areas: the promise-case of Lash Queen Feline Blacks mascara, the Re-Plasty skincare range inspired by aesthetic medicine, the exceptional concentration of plant native cells in Powercell, the edelweiss and its ability to reverse the effects of time on the skin in Prodigy Reversis.../n/n
        
        Helena Rubinstein brand puts the Avant-Garde at the heart of its approach and builds partnerships with the most renowned scientists in their respective fields. Among them are Doctor Michel Pfulg - Laclinic-Montreux Director and plastic & cosmetic surgeon -, and Doctor Jean-Marc Lemaître - INSERM Research Director -./n/n
        
        Today, Helena Rubinstein offers women its pioneer scientific expertise in stem cells, its ultra-premium approach of Beauty and it made-to-measure skincare protocols; so that they can take control of their beauty and their lives./n`,
      },
      {
        title: "RALPH LAUREN",
        path: "/galleryImages/luxe/ten.jpg",
        label: "/galleryImages/luxe/ten-b.svg",
        heading:
          "In 1978, Ralph Lauren expanded the brand to include fragrances, introducing Lauren for women and Polo for men. For 40 years, Ralph Lauren Fragrances has developed new fragrances that express a strong personality and a luxurious lifestyle in the tradition of the brand.",
        desc: `Ralph Lauren Fragrances is a portfolio of high-end fragrances for men and women, including World of Polo (Polo, Polo Blue, Polo Black, Polo Red), Romance, Ralph, Woman by Ralph Lauren and Ralph Lauren Collection./n/n

        Men’s Fragrances:/n/n
        
        Polo is a tribute to the rich sporting heritage./n/n
        
        Polo Blue evokes fresh air, liberty and a relaxed elegance./n/n
        
        Polo Black is bold, contemporary and sophisticated./n/n
        
        Polo Red is an adrenaline rush for the thrill-seeker in every man./n/n
        
        Women’s Fragrances:/n/n
        
        Romance is the fragrance of enduring love – committed and spontaneous./n/n
        
        Midnight Romance is the fragrance of passionate love– sensual and mysterious./n/n
        
        Tender Romance is the fragrance of tender new love – enchanting and hopeful./n/n
        
        Ralph is a fragrance that captures your playfulness and optimism./n/n
        
        Ralph Fresh is refreshing, intense and full of life./n/n
        
        Ralph Love is a sweet floral fragrance for your special moments./n/n
        
        Woman by Ralph Lauren, a scent that embodies modern femininity and the duality of women./n/n
        
        Ralph Lauren Collection:/n/n
        
        Ralph Lauren Collection is timeless experiences through a collection of exquisite fragrances, complemented by a complete line of home, travel and body products as well as luxury accessories in handcrafted leather./n`,
      },
      {
        title: "VIKTOR&ROLF",
        path: "/galleryImages/luxe/eleven.jpg",
        label: "/galleryImages/luxe/eleven-b.svg",
        heading:
          "Viktor&Rolf has always presented a unique vision of fashion, where spectacular meets elegant. For the past twenty years, their collections have brought imagination into a whirlwind of fabrics, telling fabulous tales.",
        desc: `After their graduation from the Arnhem Academy of Art and Design, designers Viktor Horsting and Rolf Snoeren founded their avant-garde design house in 1993. Widely recognized and respected for their provocative couture and conceptual glamour, creating spectacular beauty and unexpected elegance are at the core of Viktor&Rolf./n/n

        Launched in 2005, Viktor&Rolf’s Flowerbomb fragrance is an explosive floral potion that evokes a sensory experience, creating the possibility to transform anything into something beautiful. An addictive bouquet, a host of sweet floral layers mingle jasmine, rose, freesia and orchids, underlined with patchouli. A lingering fragrance, leaving a very chic, swirling trail./n/n
        
        In 2011, six year after the creation of Flowerbomb, Viktor&Rolf overturns once again conventions and reveals a new masculine fragrance that makes no concessions: Spicebomb./n/n
        
        In January 2014, the 2014 Spring-Summer Haute Couture fashion show reached a climax with a spectacular finale during which Viktor&Rolf revealed their new feminine fragrance, Bonbon. A timeless sensation inviting a pure and absolute pursuit of pleasure. Seduced by an auto-erotic sensory experience, she who wears Bonbon, dresses herself in perfume whilst creating her own story. This self-indulgent moment belongs exclusively to her. She is empowered, beautiful and complete./n`,
      },
      {
        title: "CACHAREL",
        path: "/galleryImages/luxe/twelve.jpg",
        label: "/galleryImages/luxe/twelve-b.svg",
        heading:
          "Synonymous with youth and liberty since 1978, Cacharel Fragrances are created as an olfactory expression of each phase of a young woman’s life.",
        desc: `Cacharel was one of the first fashion houses in the 1960s to introduce the Ready-to-Wear concept as an alternative line to Haute Couture, and, always ahead of its time, has been able to keep its fresh, young and dynamic image./n/n

        Cacharel celebrates femininity and romanticism with its mythical perfumes including Anaïs Anaïs, a girl’s first perfume, best-seller Amor Amor, a declaration of first love and its daring fragrance Yes I Am, the fragrance of independence./n/n
        
        Anaïs Anaïs: this legendary perfume was a revolution at the time of its creation in 1978. With its scent centered around the lily flower, both innocent and mysterious and its white opaline bottle, it has become the symbol of romanticism and tenderness. The first high-end perfume./n/n
        
        Amor Amor: the first love, passionate and longing, colorful and young. The bottle is a beating heart, a grenade that is set off in an explosion of passion. Launched in 2003, its fruity-floral fragrance is a true statement./n/n
        
        Yes I Am: intended to disrupt, the fragrance interprets the generation of young women’s newfound independence. Cacharel dares a scent like none before: the first “Spicy Cremoso”, based on a contrast built around Cardamom. An all-new perfume design, one of the most powerful symbols of femininity, the red lipstick, is recast as a scent. Yes I Am is a declaration of self-affirmation representing a generation of independent young women./n`,
      },
      {
        title: "DIESEL",
        path: "/galleryImages/luxe/thirteen.jpg",
        label: "/galleryImages/luxe/thirteen-b.svg",
        heading:
          "Wearing Diesel makes for successful living in a positive, jubilant community that plays by its own rules. More than a brand, a modern lifestyle, a mix of refinement, provocation and humor.",
        desc: `Diesel is a fashion industry legend, a pioneer in the world of denim and casual wear. Never forsaking its original DNA, Diesel has evolved into an iconic global lifestyle brand, beyond the trends, and yet ahead of them. Despite the company's extraordinary growth, its philosophy remains the same as in 1978, when Renzo Rosso envisaged Diesel as a brand that would stand for passion, individuality and self-expression. Today, Diesel continues to blaze its own trail. It is on a continuing mission to challenge the status quo, experiment with new ideas, innovate and provoke./n/n

        Diesel fragrances reuse classic perfume codes with a modern twist to create unique, multifaceted scents, that are colorful, often unconventional, and with a real personality./n/n
        
        Diesel Only The Brave embodies Diesel masculinity. It’s an ode to bravery./n/n
        
        Bravery is about giving yourself the freedom to be who you really are, whoever you are. It’s about defining your values and having the audacity to defend them. Bravery is finding the courage to be the hero of your own life. Tomorrow belongs to the brave and the brave only./n`,
      },
      {
        title: "YUE SAI",
        path: "/galleryImages/luxe/fifteen.jpg",
        label: "/galleryImages/luxe/fifteen-b.svg",
        heading:
          "Every Chinese woman has two sides of beauty: the inner wisdom and outer confidence. With the deep understanding of Chinese women for 25 years, Yuesai appreciates the uniqueness of Chinese Beauty – a balanced combination of Outer Beauty and Inner Wisdom. Yuesai endeavours to develop beauty products specially made for Chinese women, inspired by the Traditional Chinese Medicine and boosted by Advanced technology to extract active ingredients for high efficacy and safety. Let the modern Chinese beauty bloom inside-out.",
        desc: `Founded by Madame Yue-Sai Kan in 1992, Yue Sai brought modern beauty to Chinese women for the first time./n/n

        In 1998, Yue Sai has been already the leading brand in high-end Chinese beauty market. L’Oreal group expanded in China in 2004, it was further enhanced by acquiring one of the cultural prides of China, with a revolutionary innovation of using Traditional Chinese Medicine(TCM), boosted with the advanced extraction techniques for both skin care products and makeup. With its unique and extensive research on the Chinese skin needs and skin tones, Yue Sai offers products adapted for Asian women, and promotes the Chinese “holistic” approach to inner and outer beauty./n/n
        
        In 2007, Yue Sai launched Ganoderma Vital Essential, which quickly became the flagship of the brand. Ganoderma Vital Essential contains a unique extraction of Ganoderma, a rare fungus, known for its stimulating properties used in TCM./n/n
        
        Representing modern Chinese beauty and pride, Yue Sai held the exclusive beauty sponsorship for Miss Universe in 2011./n/n
        
        2016 marks the birth of a New Brand Equity Platform of Yue Sai in 6 Dimensions, well spelled by Inspiration from TCM, Natural, Modern Science, Inner Outer Beauty, Authenticity, Modern Chinese Beauty./n/n
        
        The most iconic products including: Vitalize Ganoderma Youth Preserving Toner, Vitalize Ganoderma Cushion BB, Regenerate Ginseng Restoring Eye Cream and Cordyceps lipsticks./n/n`,
      },
      {
        title: "ATELIER COLOGNE",
        path: "/galleryImages/luxe/sixteen.jpg",
        label: "/galleryImages/luxe/sixteen-b.svg",
        heading:
          "Established in 2009, Atelier Cologne is the first Maison de Parfum creating Pure Perfumes inspired by the legendary Eau de Cologne. The founders, Sylvie Ganter and Christophe Cervasel, gave birth to a new olfactive family, “Cologne Absolue”.",
        desc: `For the first time, citruses are blended with the most precious natural raw materials for perfectly balanced creations and exceptional lasting power./n/n

        Each Cologne Absolue tells the story of treasured emotions and powerful memories such as Orange Sanguine telling the story of a convivial seaside breakfast during a beautiful spring morning. Clémentine California dive us in the heart of Hollywood, in the footsteps of a film producer looking for the actress of his dreams./n`,
      },
      {
        title: "VALENTINO",
        path: "/galleryImages/luxe/seventeen.jpg",
        label: "/galleryImages/luxe/seventeen-b.svg",
        heading:
          "Founded in 1960 in Rome, Maison Valentino holds a unique role in the luxury universe thanks to its long standing tradition in Haute Couture and the innovative contemporary vision of Creative Director Pierpaolo Piccioli. ",
        desc: `Under the successful direction of CEO Stefano Sassi and Creative Director Pierpaolo Piccioli, Valentino today embodies the image of beauty, of the extraordinary and of modernity, and as such, provides a strong inspiration for fragrance and beauty creation. The top three most popular fragrances today are Valentino Donna, Valentino Uomo and Valentina./n`,
      },
    ],
  },
};
