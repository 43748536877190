// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import { ImageString } from "../../../const/assets/ImageString";
import "./Tut.css";
import ReactPlayer from "react-player";
import Spinner from "../spinner.gif";
import { isMobileOnly } from "react-device-detect";

const states = {
  // introVideo: -1,
  introCard: -1,
  tutCard: 0,
  leaderboard: 2,
};

const HeaderBackground = {
  background: `url(${"/assets/images/head_bg.jpg"})`,
  backdropFilter: "blur(34px)",
  width: "100%",
  justifyContent: "center",
  backgroundSize: "100%",
};

const TutCard = [
  {
    link: "/3dAssets/UI/Tut/arrows.png",
    header: "Arrows",
    description: "Tap the left and right arrows to view more.",
  },
  {
    link: "/3dAssets/UI/Tut/hotspot.png",
    header: "HOTSPOTS",
    description: "Tap on any hotspot to enter that selection.",
  },
  {
    link: "/3dAssets/UI/Tut/Menu.png",
    header: "MENU",
    description: "Use the menu icons for quick navigation.",
  },
];

export default class LobbyTut extends Component {
  state = {
    currentState: states.introCard,
  };

  handleContinue = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.props.skip();
  };

  showNextCard = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.setState((prev) => ({
      currentState: prev.currentState + 1,
    }));
  };

  render() {
    return (
      <>
        <div className="lobbyTutContainer">
          {this.state.currentState === states.introVideo && (
            <>
              <div
                className="loading"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={Spinner} alt="" />
              </div>
              <div
                style={{
                  height: "30vw",
                  width: "calc(1.77 * 30vw)",
                  border: "8px solid #fff",
                  position: "relative",
                }}
              >
                <img
                  style={{
                    position: "absolute",
                    top: "-1.5%",
                    right: "-8%",
                    width: "3rem",
                  }}
                  src={ImageString.CLOSEBUTTON}
                  className="closeButton-mediaModal"
                  alt="MediaModalCLoseButton"
                  onClick={() => {
                    this.showNextCard();
                    this.props.checkIfAudioCanPlayUnderMediaModal(true, false);
                    sessionStorage.setItem("Lobby-tut", "visited");
                  }}
                ></img>
                <ReactPlayer
                  id="audiVideoPlayer"
                  className="mg-b25"
                  url={"https://player.vimeo.com/video/549630831"}
                  playing={true}
                  volume={0.5}
                  controls={true}
                  playsinline={true}
                  width="100%"
                  height="100%"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onEnded={(e) => this.showNextCard(e)}
                ></ReactPlayer>
              </div>
            </>
          )}
          {this.state.currentState === states.introCard && (
            <>
              <div className="tutCardContainer width-cal botm-brdr">
                <div
                  className="signinBox__heading__container"
                  style={{ borderBottom: "none" }}
                >
                  <div
                    className="tutCardContainer-header"
                    style={{
                      ...HeaderBackground,
                    }}
                  >
                    <img src={"/3dAssets/UI/head.png"} alt="logo1" />
                  </div>
                  <div className="signinBox__heading__gradient"></div>
                </div>

                <div
                  className="tutCardContainer-body tutCardContainer-body-extraPd"
                  style={{
                    paddingTop: "2rem",
                    paddingBottom: "2rem",
                    minHeight: isMobileOnly ? "auto" : "fit-content",
                  }}
                >
                  <div className="tutCardContainer-body-textContainer">
                    <strong style={{ color: "#008A51" }}>
                      <h3
                        style={{
                          fontWeight: "bold",
                          color: "#008A51",
                        }}
                      >
                        Welcome To
                      </h3>
                      <h2
                        style={{
                          fontWeight: "bold",
                          marginBottom: "1rem",
                        }}
                        className="top-tut-main-heading"
                      >
                        RELIANCE T.U.P 8.0
                      </h2>
                    </strong>
                    <p className="top-tut-para">
                      We welcome you to a virtual avatar of our state-of-the-art
                      Reliance Corporate Park. This is where you get to witness
                      the big ideas of tomorrow! Come and explore a world of new
                      innovations that shape our NOW!
                    </p>
                    {/* <br /> */}
                    <p className="top-tut-para">
                      Do explore the various engagement zones and don't forget
                      to have fun!
                    </p>
                    {/* <br /> */}
                    {/* <br /> */}
                    <p className="top-tut-para" style={{ marginBottom: 0 }}>
                      Team TUP
                    </p>
                    {/* <br /> */}
                    <button
                      className="tutCardButton btn br-lPurple btn-red mg-t30"
                      onClick={(e) => this.showNextCard(e)}
                      style={{
                        marginLeft: "50%",
                        transform: "translateX(-50%)",
                      }}
                    >
                      CONTINUE
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
          {this.state.currentState === states.tutCard && (
            <div className="tutCardContainer tutCardContainer-sm botm-brdr">
              <div
                className="tutCardContainer-header"
                style={{
                  ...HeaderBackground,
                  background: "#008A51",
                  justifyContent: "flex-start",
                }}
              >
                <div>Tutorial</div>
              </div>
              <div
                className="tutCardContainer-body "
                style={{
                  minHeight: "60vh",
                }}
              >
                {TutCard.map((item) => (
                  <div
                    key={item.header}
                    className="tutCardContainer-bodyItem"
                    style={{ paddingBottom: "1rem" }}
                  >
                    <img alt={item.header} src={item.link}></img>
                    <div
                      className="bodyItem-text"
                      style={{ marginLeft: "1rem" }}
                    >
                      <h2 style={{ color: "#008A51" }}>{item.header}</h2>
                      <div>{item.description}</div>
                    </div>
                  </div>
                ))}
                <div className="text-center">
                  <button
                    style={{
                      padding: "1.25rem 2.5rem",
                      textTransform: "uppercase",
                    }}
                    className="tutCardButton btn br-lPurple btn-red mg-t30"
                    onClick={(e) => this.handleContinue(e)}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          )}
          {this.state.currentState === states.leaderboard && (
            <>
              <div className="tutCardContainer width-cal">
                <div className="signinBox__heading__container">
                  <div
                    className="tutCardContainer-header"
                    style={HeaderBackground}
                  >
                    <img
                      src={ImageString.LOGOSTRATETHON}
                      alt="logo1"
                      // style={}
                    ></img>
                    {/* <img src={ImageString.LOGOSEASON2} alt="logo2"></img> */}
                  </div>
                  {/* <div className="signinBox__heading__gradient"></div> */}
                </div>

                <div className="tutCardContainer-body tutCardContainer-body-extraPd">
                  <div className="tutCardContainer-body-textContainer">
                    <strong>
                      <h3>SCOREBOARD</h3>
                    </strong>
                    <br></br>Want to be in the running for a Westfield Gift
                    Card? <br></br>
                    <br></br>
                    {/* <br></br>Highest points on the table win, pull up your socks
                    and battle it out.<br></br> Play exciting games and win as a
                    team to outrank others all vying for the Champions Trophy. */}
                    You can accumulate points throughout the day by completing
                    the Workshop and Conference evaluations, Word Hunt and
                    taking a selfie in the Photo Booth!<br></br>
                    <br></br>
                    You can see your points tally at any time by clicking on the
                    'Scoreboard'. Staff with the highest points tally at the end
                    of the day will be announced in the Closing Address.
                    <br></br>
                    <br></br>
                    Good luck!
                  </div>
                </div>
                <div
                  className="button"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: "1rem",
                  }}
                >
                  <button
                    style={{ marginTop: "0 !important" }}
                    className="tutCardButton btn  btn-yellow mg-t30"
                    onClick={(e) => this.handleContinue(e)}
                  >
                    {`Continue`}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}
