import React, { Component } from "react";
import { isMobileOnly } from "react-device-detect";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Holder from "../auth/login/Holder";
import Intro from "../auth/login/Intro";
import Login from "../auth/login/Login";
import { UserContext } from "../auth/providers";
import { AppString } from "../firebase/AppString";
import { LanguageContext } from "../../context/LanguageContext";
import {
  addLoginAnalytics,
  checkForSpecialUser,
  firestore,
  getProjectData,
  getProjectDataListener,
  getProjectRealTimeData,
  loadUser,
  signInWithId,
  turnOnUserCard,
} from "../firebase/firebase";
import Home from "../Home/Home";

class Application extends Component {
  state = {
    dbUpdated: false,
    canEnter: false,
    canShowIntro: false,
    canShowRotate: true,
    liveStatus: true,
    message: "",
  };

  loginFromConsole = () => {
    signInWithId("daman@loreal.com", "daman@loreal.com123456");
  };
  componentDidMount() {
    window.app = this;
    window.parent.adminLogin = this.loginFromConsole;
    // this.getTime();
    // firestore
    //   .collection(AppString.USERS_COLLECTION)
    //   .get()
    //   .then((res) => {
    //     const arr = [];
    //     res.forEach((item) => {
    //       arr.push(item.data());
    //     });
    //     console.log(arr);
    //   });

    var checkVersion = function () {
      var agent = window.navigator.userAgent,
        start = agent.indexOf("OS");
      console.log(agent);
      if (
        (agent.indexOf("iPhone") > -1 || agent.indexOf("iPad") > -1) &&
        start > -1
      ) {
        window.parent.ios_V = window.Number(
          agent.substr(start + 3, 3).replace("_", ".")
        );
        return window.parent.ios_V;
      }
      window.parent.ios_V = null;
      return 0;
    };
    const ver = checkVersion();

    var checkVersionV2 = function () {
      var agent = window.navigator.userAgent,
        start = agent.indexOf("OS");
      console.log(agent);
      if (
        (agent.indexOf("iPhone") > -1 || agent.indexOf("iPad") > -1) &&
        start > -1
      ) {
        window.parent.ios_V2 = agent.substr(start + 3, 4).replace("_", ".");

        return window.parent.ios_V2;
      }
      window.parent.ios_V2 = null;
      return 0;
    };
    const ver2 = checkVersionV2();

    var checkMacV = () => {
      const agent = window.navigator.userAgent;
      if (
        agent.includes("Mac") ||
        agent.includes("MAC") ||
        agent.includes("mac")
      ) {
        if (agent.includes("Version/15")) {
          window.parent.isMac15 = true;
        } else {
          window.parent.isMac15 = false;
        }
      }
    };
    checkMacV();

    this.getLiveStatus();
    if (isMobileOnly) {
      document.addEventListener("click", () => {
        console.log("aaa");
        var elem = document.body;

        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
          /* Safari */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          /* IE11 */
          elem.msRequestFullscreen();
        }
      });
    }
    this.getCurrentTimeStampV2();
    if (isMobileOnly) {
      this.setState({
        canShowIntro: false,
      });
    } else {
      if (!sessionStorage.getItem("IntoPlayed")) {
        this.setState({
          canShowIntro: false, //previous true
        });
      }
    }
  }
  realTimeDataUpdate = () => {
    firestore
      .collection(AppString.USERS_COLLECTION)
      .doc(this.context.email)
      .onSnapshot(async (query) => {
        window.isWkAdmin = query.data().isAdmin ? query.data().isAdmin : false;
        window.workshop = query.data().workshop ? query.data().workshop : true;
        this.setState((prev) => ({ dbUpdated: !prev.dbUpdated }));
      });
  };

  getLiveStatus = () => {
    return new Promise(async (res, rej) => {
      try {
        firestore
          .collection(AppString.BACKSATGE)
          .doc("realtime")
          .onSnapshot((doc) => {
            if (doc.exists) {
              this.setState({
                liveStatus: doc.data().live,
                message: doc.data().message,
              });
            } else {
            }
            res();
          });

        res();
      } catch (err) {
        console.log(err);
        res(err);
      }
    });
  };
  getCurrentTimeStampV2 = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await fetch("https://dj-timeserver.glitch.me");
        const result = await res.json();
        var currDateWeb = new Date(result.epoch);
        window.todaysDateTimestamp = `${currDateWeb.getFullYear()}-${
          currDateWeb.getMonth() + 1
        }-${currDateWeb.getDate()}`;
        console.log(window.todaysDateTimestamp);

        let tomorrowDate = new Date(result.epoch);
        tomorrowDate.setDate(tomorrowDate.getDate() + 1);
        let tomorrowTimestamp = tomorrowDate.getTime();
        let timeInTommorrow = tomorrowTimestamp - currDateWeb.getTime();
        this.timeInTommorrowTimer = setTimeout(() => {
          this.getCurrentTimeStampV2();
        }, timeInTommorrow);

        currDateWeb = currDateWeb.getTime();
        resolve(currDateWeb);
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };

  getTime = () => {
    fetch("https://worldtimeapi.org/api/timezone/Etc/UTC")
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result && result.datetime) {
            var currDateWeb = new Date(result.datetime);
            window.todaysDateTimestamp = `${currDateWeb.getFullYear()}-${
              currDateWeb.getMonth() + 1
            }-${currDateWeb.getDate()}`;
            console.log(window.todaysDateTimestamp);
            var currDateLocal = new Date();
            var webTimeDiff = currDateWeb.getTime() - currDateLocal.getTime();
            window.webTimeDiff = webTimeDiff;
            this.setState({ webTimeDiff });

            let tomorrowDate = new Date(result.datetime);
            tomorrowDate.setDate(tomorrowDate.getDate() + 1);
            let tomorrowTimestamp = tomorrowDate.getTime();
            let timeInTommorrow = tomorrowTimestamp - currDateWeb.getTime();
            this.timeInTommorrowTimer = setTimeout(() => {
              this.getNewDate();
            }, timeInTommorrow);
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      )
      .catch((err) => {
        console.log(err);
      });
  };
  getNewDate = async () => {
    try {
      const res = await fetch("https://worldtimeapi.org/api/timezone/Etc/UTC");
      const result = await res.json();
      if (result && result.datetime) {
        var currDateWeb = new Date(result.datetime);
        window.todaysDateTimestamp = `${currDateWeb.getFullYear()}-${
          currDateWeb.getMonth() + 1
        }-${currDateWeb.getDate()}`;
        addLoginAnalytics(this.context).then(() => {
          sessionStorage.setItem("analyticsLogged", "true");
        });

        let tomorrowDate = new Date(result.datetime);
        tomorrowDate.setDate(tomorrowDate.getDate() + 1);
        let tomorrowTimestamp = tomorrowDate.getTime();
        let timeInTommorrow = tomorrowTimestamp - currDateWeb.getTime();
        this.timeInTommorrowTimer = setTimeout(() => {
          this.getNewDate();
        }, timeInTommorrow);
      }
    } catch (err) {
      console.log(err);
    }
  };

  cleanMemory = () => {
    if (this.timeInTommorrowTimer) {
      clearTimeout(this.timeInTommorrowTimer);
    }
  };

  componentWillUnmount() {
    this.cleanMemory();
  }

  appConfig = async (userAuth) => {
    try {
      this.setState({
        canEnter: false,
      });
      sessionStorage.setItem(`${"audi"}-tut`, "visited");
      const userData = await firestore
        .collection(AppString.USERS_COLLECTION)
        .doc(userAuth.email)
        .get();
      window.workshop = userData.data().workshop
        ? userData.data().workshop
        : true;
      await getProjectData();
      this.setState({
        canEnter: true,
      });

      this.realTimeDataUpdate();
    } catch (error) {
      console.log(error);
    }
  };

  toggleRotateScreen = (value) => {
    // console.log(value ? "can show rotate" : " can not show rotate");
    this.setState({
      canShowRotate: value,
    });
  };

  render() {
    let user = this.context;
    return (
      <>
        {user && this.state.canEnter ? (
          <div onContextMenu={(e) => e.preventDefault()}>
            <Router>
              <Switch>
                <Route path="/">
                  <LanguageContext.Consumer>
                    {(language) => {
                      return (
                        <Home
                          languageContext={language}
                          dbUpdated={this.state.dbUpdated}
                          amWorkshop={window.morningWorkshop}
                          pmWorkshop={window.afternoonWorkshop}
                          toggleRotateScreen={this.toggleRotateScreen}
                          canShowIntro={this.state.canShowIntro}
                        ></Home>
                      );
                    }}
                  </LanguageContext.Consumer>
                </Route>
              </Switch>
            </Router>
          </div>
        ) : (
          <Router>
            <Switch>
              <Route path="/">
                <Login
                  message={this.state.message}
                  liveStatus={this.state.liveStatus}
                  webTimeDiff={this.state.webTimeDiff}
                  showLoggingIn={user ? true : false}
                />
              </Route>
            </Switch>
          </Router>
        )}
        {/* {this.state.canShowRotate && ( */}
        <>
          {/* <div className="rotate-screen-popup"> */}
          <div id="rotate">
            <div className="phone"></div>
            <div className="message">Please rotate your device!</div>
          </div>
          {/* </div> */}
        </>
        {/* )} */}
      </>
    );
  }
}
Application.contextType = UserContext;
export default Application;
