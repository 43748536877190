// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import "./Tut.css";

const TutCard = [
  {
    link: "/3dAssets/UI/AudiTut/FullScreen.png",
    header: "Full Screen",
    description: "Tap on this icon to view the content on full screen.",
  },
  {
    link: "/3dAssets/UI/AudiTut/ExitFullScreen.png",
    header: "Exit Full Screen",
    description: "Tap on this button to exit the fullscreen mode.",
  },
  {
    link: "/3dAssets/UI/AudiTut/PlayButton.png",
    header: "Play Button",
    description: "Click on the play button to start playing the video.",
  },
  {
    link: "/3dAssets/UI/AudiTut/Polls.png",
    header: "Audience Rating",
    description: "Click on this button to enter Audience Rating.",
  },
  {
    link: "/3dAssets/UI/AudiTut/Q&A.png",
    header: "Chat",
    description: "Click here to get answers to your queries.",
  },
];

const state = {
  audiTut: 0,
  zoomLink: 1,
};
const HeaderBackground = {
  background: "#008A51",
  backdropFilter: "blur(34px)",
  width: "100%",
  justifyContent: "flex-start",
};
export default class AudiTut extends Component {
  state = {
    currentState: state.audiTut,
  };

  handleContinue = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (window.specialUser) {
      if (this.state.currentState === state.audiTut) {
        this.setState({
          currentState: state.zoomLink,
        });
      } else {
        this.props.skip();
      }
    } else {
      this.props.skip();
    }
  };

  call = (event) => {
    if (event) {
      event.preventDefault();
    }
    window.location.href = window.zoomLink;
  };

  render() {
    return (
      <>
        {this.state.currentState === state.audiTut && (
          <div className="lobbyTutContainer">
            <div className="tutCardContainer tutCardContainer-sm botm-brdr">
              <div className="tutCardContainer-header" style={HeaderBackground}>
                <div>Tutorial</div>
              </div>
              <div
                className="tutCardContainer-body"
                style={{
                  paddingTop: "1rem",
                  paddingBottom: "2rem",
                  minHeight: "60vh",
                }}
              >
                {TutCard.map((item) => (
                  <div
                    key={item.header}
                    className="tutCardContainer-bodyItem"
                    style={{ paddingBottom: "1rem" }}
                  >
                    <img alt={item.header} src={item.link}></img>
                    <div
                      className="bodyItem-text"
                      style={{ marginLeft: "1rem" }}
                    >
                      <h2 style={{ color: "#008A51" }}>{item.header}</h2>
                      <div>{item.description}</div>
                    </div>
                  </div>
                ))}
                <div className="text-center">
                  <button
                    style={{
                      padding: "1.25rem 2.5rem",
                      textTransform: "uppercase",
                      borderLeft: "6px solid #008A51",
                    }}
                    className="tutCardButton btn  btn-red mg-t30"
                    onClick={(e) => this.handleContinue(e)}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.currentState === state.zoomLink && (
          <div className="lobbyTutContainer">
            <div className="tutCardContainer tutCardContainer-sm pd-b-2">
              <div className="tutCardContainer-header  bg-red">
                <div>Zoom Call</div>
              </div>
              <div className="tutCardContainer-body">
                Please click on button below to go to the zoom link.
              </div>
              <div className="text-center">
                <button
                  className="tutCardButton btn  btn-yellow mg-t30"
                  onClick={(e) => this.handleContinue(e)}
                >
                  Cancel
                </button>
                <button
                  className="tutCardButton btn  btn-yellow mg-t30"
                  onClick={(e) => this.call(e)}
                  style={{ marginLeft: "1rem" }}
                >
                  Call
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
